import api_request from "./ApiConfig";

class SubscriptionServices {
  getSubscription(parems?: object): Promise<any> {
    let url: string = "/api/v1/subscription";

    return api_request.get(url, parems);
  }
}

export default new SubscriptionServices();
