import api_request from "./ApiConfig";

class GenericService {
  static getModels(): Promise<any> {
    return api_request.get(`/api/v1/generic/models`);
  }
  static getFeatures(): Promise<any> {
    return api_request.get(`/api/v1/generic/plan-features`);
  }
}

export default GenericService;
