import React from 'react';
import {CURRENT} from './renderAuthorize';

export type IAuthorityType =
    | undefined
    | string
    | string[]
    | ((currentAuthority: string | string[]) => IAuthorityType);

/**
 * General permission check method
 * Common check permissions method
 * @param {Permission judgment} authority
 * @param {Your permission | Your permission description} currentAuthority
 * @param {Passing components} target
 * @param {no pass components | no pass components} Exception
 */
const checkPermissions = <T, K>(
    authority: IAuthorityType,
    currentAuthority: string | string[],
    target: T,
    Exception: K,
): T | K | React.ReactNode => {
    // No judgment permission. View all by default
    // Retirement authority, return target;
    if (!authority) {
        return authority;
    }
    // Array processing
    if (Array.isArray(authority)) {
        if (Array.isArray(currentAuthority)) {
            if (currentAuthority.some((item) => authority.includes(item))) {
                return target;
            }
        } else if (authority.includes(currentAuthority)) {
            return target;
        }
        return Exception;
    }

    // Deal with string
    if (typeof authority === 'string') {
        if (Array.isArray(currentAuthority)) {
            if (currentAuthority.some((item) => authority === item)) {
                return target;
            }
        } else if (authority === currentAuthority) {
            return target;
        }
        return Exception;
    }

    //Deal with function
    if (typeof authority === 'function') {
        const bool = authority(currentAuthority);
        if (bool) {
            return target;
        }
        return Exception;
    }
    throw new Error('unsupported parameters');
};

export {checkPermissions};

function check<T, K>(
    authority: IAuthorityType,
    target: T,
    Exception: K,
): T | K | React.ReactNode {
    return checkPermissions<T, K>(authority, CURRENT, target, Exception);
}

export default check;
