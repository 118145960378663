import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { IPagedData } from "models/Common";
import {
  IImpersonateLogInDataType,
  IUserCreateDataType,
  IUserDataType,
  IUserFilter,
  IUserQuery,
  IUserRoleAssignType,
  IUserUpdateDataType,
} from "models/User";
import UserService from "services/UserService";
import { getProperErrorMessagesHendelar } from "utils/errorHandelar";
import { ICommonResponseType, ICommonStateType, linksInitialValue, metaInitialValue } from "../common";

interface userInitialStateType {
  createUser: ICommonStateType;
  updateUser: ICommonStateType;
  assignRole: ICommonStateType;
  impersonateLogIn: {
    result: IImpersonateLogInDataType | null;
    loading: boolean;
    success: boolean;
    unAuthorized: boolean;
    errors: any;
  };
  getUsers: {
    result: IPagedData<IUserDataType, IUserFilter>;
    loading: boolean;
    success: boolean;
    unAuthorized: boolean;
    errors: any;
  };
}

const initialState: userInitialStateType = {
  getUsers: {
    result: {
      data: [],
      links: linksInitialValue,
      meta: metaInitialValue,
      filters: {},
      success: false,
      message: "",
    },
    success: false,
    loading: false,
    unAuthorized: false,
    errors: null,
  },
  impersonateLogIn: {
    result: null,
    success: false,
    loading: false,
    unAuthorized: false,
    errors: null,
  },

  createUser: {
    success: false,
    loading: false,
    error: {
      validationErrors: [],
      othersErrors: {},
    },
  },
  assignRole: {
    success: false,
    loading: false,
    error: {
      validationErrors: [],
      othersErrors: {},
    },
  },
  updateUser: {
    success: false,
    loading: false,
    error: {
      validationErrors: [],
      othersErrors: {},
    },
  },
};

export const getUsers = createAsyncThunk<IPagedData<IUserDataType, IUserQuery>, { filters?: IUserQuery }>(
  "user/all_user_list",
  async (_, thunkApi) => {
    try {
      return await UserService.getUsers(_.filters);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

// create user
export const createUser = createAsyncThunk<ICommonResponseType, { data: IUserCreateDataType }>("user/create_user", async (_, thunkApi) => {
  try {
    const data: ICommonResponseType = await UserService.createUser(_.data);

    if (data) {
      message.success("Successfully  added");
    }
    thunkApi.dispatch(getUsers({}));

    return data;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

//impersonateLogIn
export const impersonateLogIn = createAsyncThunk<IImpersonateLogInDataType, { requestUserId: number }>(
  "user/impersonate_login",
  async (_, thunkApi) => {
    try {
      const data = await UserService.impersonateLogIn(_.requestUserId);

      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

// create user
export const assignUserRole = createAsyncThunk<ICommonResponseType, { data: IUserRoleAssignType }>(
  "user/role-update",
  async (_, thunkApi) => {
    try {
      const data: ICommonResponseType = await UserService.assignUserRole(_.data);

      if (data) {
        message.success("Successfully  added");
      }
      thunkApi.dispatch(getUsers({}));

      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
// update  user
export const updateUser = createAsyncThunk<ICommonResponseType, { id: number; data: IUserUpdateDataType; filters: IUserQuery }>(
  "user/update_user",
  async (_, thunkApi) => {
    try {
      const data: ICommonResponseType = await UserService.updateUser(_.id, _.data);

      if (data) {
        message.success("Successfully  added");
      }
      thunkApi.dispatch(
        getUsers({
          filters: _.filters,
        }),
      );

      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

// reducers -> reduce to a specific state -> changes state
export const usersSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUserState: (state: userInitialStateType) => {
      state.createUser.success = false;
      state.updateUser.success = false;
      state.impersonateLogIn.success = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUsers.pending, (state) => {
      state.getUsers.loading = true;
      state.getUsers.unAuthorized = false;
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.getUsers.result = action.payload;
      state.getUsers.loading = false;
      state.getUsers.unAuthorized = false;
      state.getUsers.success = true;
    });
    builder.addCase(getUsers.rejected, (state, action: any) => {
      state.getUsers.loading = false;
      state.getUsers.success = false;
      state.getUsers.errors = action.payload.response;
      state.getUsers.result.data = [];
      if (action.payload.error && action.payload.error.response.status === 403) {
        state.getUsers.unAuthorized = true;
      }
    });

    // impersonateLogIn
    builder.addCase(impersonateLogIn.pending, (state) => {
      state.impersonateLogIn.loading = true;
      state.impersonateLogIn.unAuthorized = false;
    });
    builder.addCase(impersonateLogIn.fulfilled, (state, action) => {
      state.impersonateLogIn.result = action.payload;
      state.impersonateLogIn.loading = false;
      state.impersonateLogIn.unAuthorized = false;
      state.impersonateLogIn.success = true;
    });
    builder.addCase(impersonateLogIn.rejected, (state, action: any) => {
      state.impersonateLogIn.loading = false;
      state.impersonateLogIn.success = false;
      state.impersonateLogIn.errors = action.payload && getProperErrorMessagesHendelar(action.payload);
      state.impersonateLogIn.result = null;
      if (action.payload.error && action.payload.error.response.status === 403) {
        state.impersonateLogIn.unAuthorized = true;
      }
    });

    // create users

    builder.addCase(createUser.pending, (state: userInitialStateType) => {
      state.createUser.loading = true;
      state.createUser.success = false;
      state.createUser.error = null;
    });
    builder.addCase(createUser.fulfilled, (state: userInitialStateType) => {
      state.createUser.success = true;
      state.createUser.loading = false;
      state.createUser.error = null;
    });
    builder.addCase(createUser.rejected, (state: userInitialStateType, action: PayloadAction<any>) => {
      state.createUser.success = false;
      state.createUser.loading = false;
      state.createUser.error = action.payload && getProperErrorMessagesHendelar(action.payload);
    });

    // assign user roles

    builder.addCase(assignUserRole.pending, (state: userInitialStateType) => {
      state.assignRole.loading = true;
      state.assignRole.success = false;
      state.assignRole.error = null;
    });
    builder.addCase(assignUserRole.fulfilled, (state: userInitialStateType) => {
      state.assignRole.success = true;
      state.assignRole.loading = false;
      state.assignRole.error = null;
    });
    builder.addCase(assignUserRole.rejected, (state: userInitialStateType, action: PayloadAction<any>) => {
      state.assignRole.success = false;
      state.assignRole.loading = false;
      state.assignRole.error = action.payload && getProperErrorMessagesHendelar(action.payload);
    });

    // update user

    builder.addCase(updateUser.pending, (state: userInitialStateType) => {
      state.updateUser.loading = true;
      state.updateUser.success = false;
      state.updateUser.error = null;
    });
    builder.addCase(updateUser.fulfilled, (state: userInitialStateType) => {
      state.updateUser.success = true;
      state.updateUser.loading = false;
      state.updateUser.error = null;
    });
    builder.addCase(updateUser.rejected, (state: userInitialStateType, action: PayloadAction<any>) => {
      state.updateUser.success = false;
      state.updateUser.loading = false;
      state.updateUser.error = action.payload && getProperErrorMessagesHendelar(action.payload);
    });
  },
});

export const { resetUserState } = usersSlice.actions;

export default usersSlice.reducer;
