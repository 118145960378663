import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";

import { getProperErrorMessagesHendelar } from "utils/errorHandelar";

import { IPagedData } from "models/Common";
import { IPlanApiResponseDataType } from "models/Plan";

import {
    IDiscountUserCreateDataType,
    IDiscountUserDataType,
    IDiscountUserResponseFilter,
    IDiscountUsersInputFilter
} from "models/DiscountUsers";
import { linksInitialValue, metaInitialValue } from "redux/reducers/common";
import DiscountUserServices from "services/DiscountUserServices";

type discountUserCreateResponseType = {
  message: string | null;
  success: boolean;
};
interface IDiscountUsersCommonInitialStateType {
  success: boolean;
  loading: boolean;
  error: {
    validationErrors:
      | {
          label: string;
          message: string;
        }[]
      | [];
    othersErrors: {};
  } | null;
}

interface IDiscountUsersInitialStateType {
  addDiscount: IDiscountUsersCommonInitialStateType;

  getDiscountUsers: {
    result: IPagedData<IDiscountUserDataType, IDiscountUserResponseFilter>;
    success: boolean;
    loading: boolean;
    error: any;
    unAuthorized: boolean;
    page: number | null;
    per_page: number | null;
  };
  singleDiscount: {
    resData: IPlanApiResponseDataType | null;
  };
}

const initialState: IDiscountUsersInitialStateType = {
  addDiscount: {
    success: false,
    loading: false,
    error: {
      validationErrors: [],
      othersErrors: {},
    },
  },

  getDiscountUsers: {
    result: {
      data: [],
      links: linksInitialValue,
      meta: metaInitialValue,
      filters: {},
      success: false,
      message: "",
    },
    success: false,
    loading: false,
    error: null,
    unAuthorized: false,
    page: null,
    per_page: null,
  },
  singleDiscount: {
    resData: null,
  },
};

// get all DiscountUsers  list

export const getDiscountUsers = createAsyncThunk<
  IPagedData<IDiscountUserDataType, IDiscountUserResponseFilter>,
  { filters?: IDiscountUsersInputFilter }
>("discountUser/list", async (_, thunkApi) => {
  try {
    const data: IPagedData<IDiscountUserDataType, IDiscountUserResponseFilter> = await DiscountUserServices.getDiscountUsers(_.filters);
    return data;
  } catch (error) {
    const modifyError: any = error;

    return thunkApi.rejectWithValue(modifyError);
  }
});

// add  DiscountUser
export const addDiscountUser = createAsyncThunk<discountUserCreateResponseType, { data: IDiscountUserCreateDataType }>(
  "discountUser/add-user",
  async (_, thunkApi) => {
    try {
      const data: discountUserCreateResponseType = await DiscountUserServices.addDiscountUser(_.data);
      if (data.success) {
        message.success(data.message);
      }
      thunkApi.dispatch(getDiscountUsers({ filters: { page: 1 } }));

      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

// reducers -> reduce to a specific state -> changes state
export const discountUserSlice = createSlice({
  name: "discountUser",
  initialState,
  reducers: {
    setPageNumber: (state, action: PayloadAction<number>) => {
      state.getDiscountUsers.page = action.payload;
    },
    setPerPage: (state, action: PayloadAction<number>) => {
      state.getDiscountUsers.per_page = action.payload;
    },
    setSelectedDiscountUserByIdFromLocalState: (state, action: PayloadAction<number | string>) => {
      // if (state.getDiscount.resData) {
      //   const selectedPlan = state.getDiscount.resData.data.find((x) => x.id === action.payload);
      //   if (selectedPlan) state.singleDiscount.resData = selectedPlan;
      // }
    },

    resetDiscoutUsersState: (state) => {
      state.addDiscount.success = false;
      state.addDiscount.loading = false;
    },
  },
  extraReducers: (builder) => {
    // get discount data
    builder.addCase(getDiscountUsers.pending, (state) => {
      state.getDiscountUsers.loading = true;
      state.getDiscountUsers.success = false;
      state.getDiscountUsers.error = false;
      state.getDiscountUsers.unAuthorized = false;
    });
    builder.addCase(
      getDiscountUsers.fulfilled,
      (state, action: PayloadAction<IPagedData<IDiscountUserDataType, IDiscountUserResponseFilter>>) => {
        state.getDiscountUsers.success = true;
        state.getDiscountUsers.result = action.payload;
        state.getDiscountUsers.loading = false;
        state.getDiscountUsers.error = false;
      },
    );
    builder.addCase(getDiscountUsers.rejected, (state: IDiscountUsersInitialStateType, action: PayloadAction<any>) => {
      state.getDiscountUsers.success = false;
      state.getDiscountUsers.result.data = [];
      state.getDiscountUsers.loading = false;
      state.getDiscountUsers.error = action.payload.error;
      if (action.payload.error && action.payload.error.response.status === 403) {
        state.getDiscountUsers.unAuthorized = true;
      }
    });

    // add Service
    builder.addCase(addDiscountUser.pending, (state: IDiscountUsersInitialStateType) => {
      state.addDiscount.loading = true;
      state.addDiscount.success = false;
      state.addDiscount.error = null;
    });
    builder.addCase(addDiscountUser.fulfilled, (state: IDiscountUsersInitialStateType) => {
      state.addDiscount.success = true;
      state.addDiscount.loading = false;
      state.addDiscount.error = null;
    });
    builder.addCase(addDiscountUser.rejected, (state: IDiscountUsersInitialStateType, action: PayloadAction<any>) => {
      if (!action.payload.response.data?.success) {
        message.error(action.payload.response.data.message);
      }
      state.addDiscount.success = false;
      state.addDiscount.loading = false;
      state.addDiscount.error = action.payload && getProperErrorMessagesHendelar(action.payload);
    });
  },
});

export const { setPageNumber, setSelectedDiscountUserByIdFromLocalState, resetDiscoutUsersState, setPerPage } = discountUserSlice.actions;
export default discountUserSlice.reducer;
