import React, {Suspense} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {routes} from "routes";
import BoxBorderLoader from "../common/Loader/BoxBorderLoader";
import GuardRouter from "./GuardRouter";
import {LOCAL_STORAGE_AUTHORITY_KEY, LOCAL_STORAGE_TOKEN_KEY} from "consts/storage";

const RouterView = () => {
  return (
    <Suspense fallback={<BoxBorderLoader />}>
      <GuardRouter>
        <Switch>
          {routes.map(({ path, isProtected, component: ComponentName, exact = true, children = [] }, key) => {
            return (
              <Route
                exact={routes.length === 0 && exact}
                key={key}
                path={path}
                // render={(props: any) => {
                //   return ComponentName && <ComponentName {...props} routes={routes} />;
                // }}
                render={(props: any) => {
                  if (
                    isProtected &&
                    (!localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) || !localStorage.getItem(LOCAL_STORAGE_AUTHORITY_KEY))
                  ) {
                    return (
                      <Redirect
                        to={{
                          pathname: "/auth/login",
                          state: { from: props.location },
                        }}
                      />
                    );
                  } else if (
                    !isProtected &&
                    localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) &&
                    localStorage.getItem(LOCAL_STORAGE_AUTHORITY_KEY)
                  ) {
                    return (
                      <Redirect
                        to={{
                          pathname: "/",
                          state: { from: props.location },
                        }}
                      />
                    );
                  } else {
                    return ComponentName && <ComponentName {...props} routes={children} />;
                  }
                }}
              />
            );
          })}
        </Switch>
      </GuardRouter>
    </Suspense>
  );
};

export default RouterView;
