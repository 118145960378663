import React, {ReactElement} from 'react';
import {Result} from 'antd';
import type {IAuthorityType} from './CheckPermissions';
import check from './CheckPermissions';
import type AuthorizedRoute from './AuthorizedRoute';
import {useLocation} from 'react-router-dom';
import {RouteType} from "routes/type";
import {getAuthorityFromRouter} from "utils/helpers";
import NotFound from "views/components/common/NotFound";

type AuthorizedProps = {
    children: ReactElement,
    authority: IAuthorityType;
    noMatch?: React.ReactNode;
    routes: RouteType[];
};

type IAuthorizedType = React.FunctionComponent<AuthorizedProps> & {
    check: typeof check;
    AuthorizedRoute: typeof AuthorizedRoute;
};

const Authorized: React.FunctionComponent<AuthorizedProps> = ({
                                                                  routes,
                                                                  children,
                                                                  authority,
                                                                  noMatch = (
                                                                      <Result
                                                                          status="403"
                                                                          title="403"
                                                                          subTitle="Sorry, you are not authorized to access this page."
                                                                      />
                                                                  ),
                                                              }) => {
    const childrenRender: React.ReactNode = typeof children === 'undefined' ? null : children;
    const dom = check(authority, childrenRender, noMatch);
    const location = useLocation();
    const authorized = getAuthorityFromRouter(
        routes,
        location.pathname || '/',
    ) || null;

    return <>{authorized ? dom : <NotFound/>}</>;
};

export default Authorized as IAuthorizedType;
