import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPagedData } from "models/Common";
import {
  IDiscountUsageHistoryInputFilter,
  IDiscountUsageHistoryResponseFilter,
  IDiscountUsagesHistoryDataType,
} from "models/DiscountUserHistory";

import { linksInitialValue, metaInitialValue } from "redux/reducers/common";
import DiscountUsageHistoryServices from "services/DiscountUsageHistoryServices";

interface IDiscountUsagesHistoryInitialStateType {
  result: IPagedData<IDiscountUsagesHistoryDataType, IDiscountUsageHistoryResponseFilter>;
  loading: boolean;
  unAuthorized: boolean;
  success: boolean;
  error: any;
  page: number | null;
  per_page: number | null;
}

const initialState: IDiscountUsagesHistoryInitialStateType = {
  result: {
    data: [],
    links: linksInitialValue,
    meta: metaInitialValue,
    filters: {},
    success: false,
    message: "",
  },
  success: false,
  loading: false,
  error: null,
  unAuthorized: false,
  page: null,
  per_page: null,
};

export const getDiscountUsagesHistory = createAsyncThunk<
  IPagedData<IDiscountUsagesHistoryDataType, IDiscountUsageHistoryResponseFilter>,
  { filters?: IDiscountUsageHistoryInputFilter }
>("discountUsagesHistory/list", async (_, thunkApi) => {
  try {
    return await DiscountUsageHistoryServices.getDiscountUsagesHistory(_.filters);
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

// reducers -> reduce to a specific state -> changes state
export const discountUsagesHistorySlice = createSlice({
  name: "discountUsagesHistory",
  initialState,
  reducers: {
    setPageNumber: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setPerPage: (state, action: PayloadAction<number>) => {
      state.per_page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDiscountUsagesHistory.pending, (state) => {
      state.loading = true;
      state.unAuthorized = false;
      state.success = false;
    });
    builder.addCase(getDiscountUsagesHistory.fulfilled, (state, action: PayloadAction<any>) => {
      state.result = action.payload;
      state.loading = false;
      state.unAuthorized = false;
      state.success = true;
    });
    builder.addCase(getDiscountUsagesHistory.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.result.data = [];
      state.success = false;
      state.unAuthorized = action.payload.response && action.payload.response.status === 401 ? true : false;
    });
  },
});

export const { setPageNumber, setPerPage } = discountUsagesHistorySlice.actions;

export default discountUsagesHistorySlice.reducer;
