import {reloadAuthorized} from './Authorized';
import {LOCAL_STORAGE_AUTHORITY_KEY} from "consts/storage";

/**
 * get permission array
 */
export function getAuthority(str?: string): string | string[] {
  const authorityString = typeof str === 'undefined' && localStorage ? localStorage.getItem('__moveup_dashboard_user_authority__') : str;

  let authority;
  try {
    if (authorityString) {
      authority = JSON.parse(authorityString);
    }
  } catch (e) {
    authority = authorityString;
  }

  // If it is a string, return an array
  if (typeof authority === 'string') {
    return [authority];
  }
  if (!authority) {
    return [];
  }
  return authority;
}

/**
 * Setting Authority
 * @param authority 
 */
export function setAuthority(authority: string | string[]): void {
  const proAuthority = typeof authority === 'string' ? [authority] : authority;
  localStorage.setItem(LOCAL_STORAGE_AUTHORITY_KEY, JSON.stringify(proAuthority));
  // auto reload
  reloadAuthorized();
}
