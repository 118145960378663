import { IPagedData } from "models/Common.d";
import { linksInitialValue, metaInitialValue } from "redux/reducers/common";

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { IShopCreateType, IShopDataType, IShopFilter, IShopQuery, IShopUpdateType } from "models/Shop";
import ShopService from "services/ShopService";
import { getProperErrorMessagesHendelar } from "utils/errorHandelar";

interface IShopCommonStateType {
  success: boolean;
  loading: boolean;
  error: {
    validationErrors:
      | {
          label: string;
          message: string;
        }[]
      | [];
    othersErrors: {};
  } | null;
}

interface IShopInitialStateType {
  addShop: IShopCommonStateType;
  updateShop: IShopCommonStateType;
  getShops: {
    result: IPagedData<IShopDataType, IShopFilter>;
    success: boolean;
    loading: boolean;
    error: any;
    unAuthorized: boolean;
    page: number | null;
    per_page: number | null;
  };
  singleShop: {
    result: IShopDataType | null;
  };
}

type updateArgumentsDataType = {
  data: IShopUpdateType;
  id: number;
  filters: IShopQuery;
};

interface ICommonResponseType {
  message: string | null;
  success: boolean;
}

const initialState: IShopInitialStateType = {
  addShop: {
    success: false,
    loading: false,
    error: {
      validationErrors: [],
      othersErrors: {},
    },
  },
  updateShop: {
    success: false,
    loading: false,
    error: {
      validationErrors: [],
      othersErrors: {},
    },
  },
  getShops: {
    result: {
      data: [],
      links: linksInitialValue,
      meta: metaInitialValue,
      filters: {},
      success: false,
      message: "",
    },
    success: false,
    loading: false,
    error: null,
    unAuthorized: false,
    page: null,
    per_page: null,
  },
  singleShop: {
    result: null,
  },
};

// get all shops

export const getShops = createAsyncThunk<IPagedData<IShopDataType, IShopFilter>, { filters?: IShopQuery }>(
  "shop/get-shops",
  async (_, thunkApi) => {
    try {
      const data: IPagedData<IShopDataType, IShopFilter> = await ShopService.getShops(_.filters);
      return data;
    } catch (error) {
      const modifyError: any = error;

      return thunkApi.rejectWithValue(modifyError);
    }
  },
);

// add shop
export const addShop = createAsyncThunk<ICommonResponseType, IShopCreateType>("shop/addShop", async (value, thunkApi) => {
  try {
    const data: ICommonResponseType = await ShopService.addShop(value);

    if (data) {
      message.success("Successfully  added");
      window.location.search = "";
    }
    thunkApi.dispatch(getShops({ filters: {} }));

    return data;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

// update shop async thunk

export const updateShop = createAsyncThunk<ICommonResponseType, updateArgumentsDataType>("shop/updateShop", async (_, thunkApi) => {
  try {
    const data: ICommonResponseType = await ShopService.updateShop(_.id, _.data);

    if (data.success) {
      message.success(data.message);
    }
    thunkApi.dispatch(getShops({ filters: _.filters }));

    return data;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

// reducers -> reduce to a specific state -> changes state
export const shopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    setPageNumber: (state: IShopInitialStateType, action: PayloadAction<number>) => {
      state.getShops.page = action.payload;
    },
    setPerPage: (state: IShopInitialStateType, action: PayloadAction<number>) => {
      state.getShops.per_page = action.payload;
    },
    selectedShopByIdFromLocalState: (state: IShopInitialStateType, action: PayloadAction<number | string>) => {
      if (state.getShops.result.data.length > 0) {
        const selectedShop = state.getShops.result.data.find((x) => x.id === action.payload);

        if (selectedShop !== undefined) {
          state.singleShop.result = selectedShop;
        }
      }
    },

    resetShopState: (state: IShopInitialStateType, action: PayloadAction) => {
      state.addShop.success = false;
      state.updateShop.success = false;
    },
  },
  extraReducers: (builder) => {
    // get shop data
    builder.addCase(getShops.pending, (state: IShopInitialStateType) => {
      state.getShops.loading = true;
      state.getShops.success = false;
      state.getShops.error = false;
      state.getShops.unAuthorized = false;
    });
    builder.addCase(getShops.fulfilled, (state: IShopInitialStateType, action: PayloadAction<IPagedData<IShopDataType, IShopFilter>>) => {
      state.getShops.success = true;
      state.getShops.result = action.payload;
      state.getShops.loading = false;
      state.getShops.error = false;
    });
    builder.addCase(getShops.rejected, (state: IShopInitialStateType, action: PayloadAction<any>) => {
      state.getShops.success = false;
      state.getShops.loading = false;
      state.getShops.result.data = [];
      state.getShops.error = action.payload.error;
      if (action.payload.error && action.payload.error.response.status === 403) {
        state.getShops.unAuthorized = true;
      }
    });

    // add shop
    builder.addCase(addShop.pending, (state: IShopInitialStateType) => {
      state.addShop.loading = true;
      state.addShop.success = false;
      state.addShop.error = null;
    });
    builder.addCase(addShop.fulfilled, (state: IShopInitialStateType) => {
      state.addShop.success = true;
      state.addShop.loading = false;
      state.addShop.error = null;
    });
    builder.addCase(addShop.rejected, (state: IShopInitialStateType, action: PayloadAction<any>) => {
      state.addShop.success = false;
      state.addShop.loading = false;
      state.addShop.error = action.payload && getProperErrorMessagesHendelar(action.payload);
    });

    // update shop
    builder.addCase(updateShop.pending, (state: IShopInitialStateType) => {
      state.updateShop.loading = true;
      state.updateShop.success = false;
      state.updateShop.error = null;
    });
    builder.addCase(updateShop.fulfilled, (state: IShopInitialStateType) => {
      state.updateShop.success = true;
      state.updateShop.loading = false;
      state.updateShop.error = null;
    });
    builder.addCase(updateShop.rejected, (state: IShopInitialStateType, action: PayloadAction<any>) => {
      state.updateShop.success = false;
      state.updateShop.loading = false;
      state.updateShop.error = action.payload && getProperErrorMessagesHendelar(action.payload);
    });
  },
});

export const { setPageNumber, selectedShopByIdFromLocalState, resetShopState, setPerPage } = shopSlice.actions;
export default shopSlice.reducer;
