import { getListPaymentGateway } from "./listOfGatewaySlice";

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { IGatewayDataType } from "models/PaymentGateway.d";
import { getProperErrorMessagesHendelar } from "utils/errorHandelar";

import PaymentServices from "services/PaymentServices";

interface gateWayUpdateIntStateType {
  result: null | {
    message: string | null;
    success: boolean;
  };
  loading: boolean;
  error: {
    validationErrors:
      | {
          label: string;
          message: string;
        }[]
      | [];
    othersErrors: {};
  } | null;
}

const initialState: gateWayUpdateIntStateType = {
  result: null,
  loading: false,
  error: {
    validationErrors: [],
    othersErrors: {},
  },
};
interface IargumentsUpdateGatewayType {
  data: IGatewayDataType;
  id: string | number;
}

export const updateGateway = createAsyncThunk<
  {
    message: string | null;
    success: boolean;
  },
  IargumentsUpdateGatewayType
>("paymentGateWayUpdate/updateById", async (value, thunkApi) => {
  try {
    const data = await PaymentServices.updatePaymentGateWayId(value.id, value.data);
    thunkApi.dispatch(getListPaymentGateway());

    return data;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

// reducers -> reduce to a specific state -> changes state
export const gatewayUpdateSlice = createSlice({
  name: "paymentGateWayUpdate",
  initialState,
  reducers: {
    resetGatewayUpdateState: (state: gateWayUpdateIntStateType) => {
      state.error = null;
      state.result = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateGateway.pending, (state: gateWayUpdateIntStateType) => {
      state.loading = true;
    });
    builder.addCase(updateGateway.fulfilled, (state: gateWayUpdateIntStateType, action: PayloadAction<any>) => {
      message.success("Gateway updated successfully");
      state.result = action.payload;
      state.loading = false;
    });
    builder.addCase(updateGateway.rejected, (state, action: PayloadAction<any>) => {
      // if (err.response && err.response.status === 401) {
      //   setUnauthorized(true);
      // }

      state.loading = false;
      state.error = action.payload && getProperErrorMessagesHendelar(action.payload);
      state.result = null;
    });
  },
});

export const { resetGatewayUpdateState } = gatewayUpdateSlice.actions;
export default gatewayUpdateSlice.reducer;
