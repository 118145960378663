import { IServiceDataType } from "../models/Service";
import api_request from "./ApiConfig";

class ProductsService {
  getServices(parems?: object): Promise<any> {
    let url: string = `/api/v1/product`;
    return api_request.get(url, parems);
  }
  getServiceById(id: string | number): Promise<any> {
    let url: string = `/api/v1/product?id=${id}`;
    return api_request.get(url);
  }

  addServices(data: IServiceDataType): Promise<any> {
    return api_request.post(`/api/v1/product`, data);
  }
  updateServicesById(target_product_id: string | number, data: IServiceDataType): Promise<any> {
    return api_request.patch(`/api/v1/product/${target_product_id}`, data);
  }
}

export default new ProductsService();
