import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPagedData } from "models/Common";
import { ISubcriptionInputFilter, ISubcriptionResponseFilter, ISubscriptionDataType } from "models/Subscription";
import { linksInitialValue, metaInitialValue } from "redux/reducers/common";
import SubscriptionServices from "services/SubscriptionServices";

interface ISubscriptionInitialStateType {
  getSubscriptions: {
    result: IPagedData<ISubscriptionDataType, ISubcriptionResponseFilter>;
    success: boolean;
    loading: boolean;
    error: any;
    unAuthorized: boolean;
    page: number | null;
    per_page: number | null;
  };

  selectedSubscriptionId: number | string | null;
  singleSubscription: {
    loading: boolean;
    result: ISubscriptionDataType | null;
    errors: any;
  };
}

const initialState: ISubscriptionInitialStateType = {
  getSubscriptions: {
    result: {
      data: [],
      links: linksInitialValue,
      meta: metaInitialValue,
      filters: {},
      success: false,
      message: "",
    },
    success: false,
    loading: false,
    error: null,
    unAuthorized: false,
    page: null,
    per_page: null,
  },

  selectedSubscriptionId: null,
  singleSubscription: {
    loading: false,
    result: null,
    errors: "",
  },
};

export const getSubscriptionList = createAsyncThunk<
  IPagedData<ISubscriptionDataType, ISubcriptionResponseFilter>,
  { filters?: ISubcriptionInputFilter }
>("subcription/list", async (_, thunkApi) => {
  try {
    return await SubscriptionServices.getSubscription(_.filters);
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

// reducers -> reduce to a specific state -> changes state
export const subcriptionSlice = createSlice({
  name: "subcription",
  initialState,
  reducers: {
    setPageNumber: (state: ISubscriptionInitialStateType, action: PayloadAction<number>) => {
      state.getSubscriptions.page = action.payload;
    },
    setPerPage: (state: ISubscriptionInitialStateType, action: PayloadAction<number>) => {
      state.getSubscriptions.per_page = action.payload;
    },
    setSubcriptionId: (state: ISubscriptionInitialStateType, action: PayloadAction<number>) => {
      state.selectedSubscriptionId = action.payload;
    },
    resetSubcriptionState: (state: ISubscriptionInitialStateType) => {
      state.selectedSubscriptionId = null;
      state.getSubscriptions.loading = false;
    },

    setSelectedSubcriptionByIdFromLocalState: (state: ISubscriptionInitialStateType, action: PayloadAction<number>) => {
      const selectedSubcription = state.getSubscriptions.result.data.find((x) => x.id === action.payload);

      if (selectedSubcription) state.singleSubscription.result = selectedSubcription;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSubscriptionList.pending, (state: ISubscriptionInitialStateType) => {
      state.getSubscriptions.loading = true;
      state.getSubscriptions.unAuthorized = false;
    });
    builder.addCase(
      getSubscriptionList.fulfilled,
      (state: ISubscriptionInitialStateType, action: PayloadAction<IPagedData<ISubscriptionDataType, ISubcriptionResponseFilter>>) => {
        state.getSubscriptions.result = action.payload;
        state.getSubscriptions.loading = false;
        state.getSubscriptions.unAuthorized = false;
      },
    );
    builder.addCase(getSubscriptionList.rejected, (state: ISubscriptionInitialStateType, action: PayloadAction<any>) => {
      state.getSubscriptions.loading = false;
      state.getSubscriptions.error = action.payload.response;
      state.getSubscriptions.result.data = [];
      state.getSubscriptions.unAuthorized = action.payload.response && action.payload.response.status === 401 ? true : false;
    });
  },
});

export const { setSubcriptionId, resetSubcriptionState, setPageNumber, setPerPage, setSelectedSubcriptionByIdFromLocalState } =
  subcriptionSlice.actions;

export default subcriptionSlice.reducer;
