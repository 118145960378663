import { IPageLink, IPageMeta } from "models/Common";

export interface ICommonStateType {
  success: boolean;
  loading: boolean;
  error: {
    validationErrors:
      | {
          label: string;
          message: string;
        }[]
      | [];
    othersErrors: {};
  } | null;
}

export interface ICommonResponseType {
  message: string | null;
  success: boolean;
}

export const linksInitialValue: IPageLink = {
  first: null,
  last: null,
  prev: null,
  next: null,
};

export const metaInitialValue: IPageMeta = {
  current_page: 0,
  from: 0,
  last_page: 0,
  links: [],
  path: null,
  per_page: 0,
  to: 0,
  total: 0,
};
