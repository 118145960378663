import { combineReducers } from "@reduxjs/toolkit";
import permissionReducer from "./reducers/aclManage/permission/permissionSlice";
import roleReducer from "./reducers/aclManage/role/roleSlice";
import authReducer from "./reducers/auth/authSlice";
import discountReducer from "./reducers/discount/discount/discountSlice";
import discountUsagesHistoryReducer from "./reducers/discount/discountUsagesHistory/discountUsageHistorySlice";
import discountUserReducer from "./reducers/discount/discountUsers/discountUsersSlice";
import genericReducer from "./reducers/generic/genericSlice";
import gatewayUpdateReducer from "./reducers/payment/gateWayUpdateSlice";
import listOfGatewayReducer from "./reducers/payment/listOfGatewaySlice";
import shopReducer from "./reducers/shopManage/shop/shopSlice";
import storeReducer from "./reducers/storeManage/store/storeSlice";
import planReducer from "./reducers/subcription/plan/planSlice";
import planGroupReducer from "./reducers/subcription/planGroup/planGroupSlice";
import serviceReducer from "./reducers/subcription/service/serviceSlice";
import subcriptionReducer from "./reducers/subcription/subcription/subcriptionSlice";
import usersReducer from "./reducers/user/usersSlice";

const rootReducer = combineReducers({
  gatewayUpdateReducer,
  listOfGatewayReducer,
  discountReducer,
  serviceReducer,
  planReducer,
  discountUsagesHistoryReducer,
  usersReducer,
  discountUserReducer,
  subcriptionReducer,
  shopReducer,
  storeReducer,
  authReducer,
  roleReducer,
  permissionReducer,
  genericReducer,
  planGroupReducer,
});

export default rootReducer;
