import { IPagedData } from "models/Common.d";
import { linksInitialValue, metaInitialValue } from "redux/reducers/common";

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { IStoreCreateType, IStoreDataType, IStoreFilter, IStoreQuery, IStoreUpdateType } from "models/Store";
import StoreService from "services/StoreService";
import { getProperErrorMessagesHendelar } from "utils/errorHandelar";

interface IStoreCommonStateType {
  success: boolean;
  loading: boolean;
  error: {
    validationErrors:
      | {
          label: string;
          message: string;
        }[]
      | [];
    othersErrors: {};
  } | null;
}

interface IStoreInitialStateType {
  addStore: IStoreCommonStateType;
  updateStore: IStoreCommonStateType;
  getStores: {
    result: IPagedData<IStoreDataType, IStoreFilter>;
    success: boolean;
    loading: boolean;
    error: any;
    unAuthorized: boolean;
    page: number | null;
    per_page: number | null;
  };
  singleStore: {
    result: IStoreDataType | null;
  };
}

type updateArgumentsDataType = {
  data: IStoreUpdateType;
  id: number;
  filters: IStoreQuery;
};

interface ICommonResponseType {
  message: string | null;
  success: boolean;
}

const initialState: IStoreInitialStateType = {
  addStore: {
    success: false,
    loading: false,
    error: {
      validationErrors: [],
      othersErrors: {},
    },
  },
  updateStore: {
    success: false,
    loading: false,
    error: {
      validationErrors: [],
      othersErrors: {},
    },
  },
  getStores: {
    result: {
      data: [],
      links: linksInitialValue,
      meta: metaInitialValue,
      filters: {},
      success: false,
      message: "",
    },
    success: false,
    loading: false,
    error: null,
    unAuthorized: false,
    page: null,
    per_page: null,
  },
  singleStore: {
    result: null,
  },
};

// get all stores

export const getStores = createAsyncThunk<IPagedData<IStoreDataType, IStoreFilter>, { filters: IStoreQuery }>(
  "store/get-stores",
  async (_, thunkApi) => {
    try {
      const data: IPagedData<IStoreDataType, IStoreFilter> = await StoreService.getStores(_.filters);
      return data;
    } catch (error) {
      const modifyError: any = error;

      return thunkApi.rejectWithValue(modifyError);
    }
  },
);

// add store
export const addStore = createAsyncThunk<ICommonResponseType, IStoreCreateType>("store/addStore", async (value, thunkApi) => {
  try {
    const data: ICommonResponseType = await StoreService.addStore(value);

    if (data) {
      message.success("Successfully  added");
      window.location.search = "";
    }
    thunkApi.dispatch(getStores({ filters: {} }));

    return data;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

// update store async thunk

export const updateStore = createAsyncThunk<ICommonResponseType, updateArgumentsDataType>("store/updateStore", async (_, thunkApi) => {
  try {
    const data: ICommonResponseType = await StoreService.updateStore(_.id, _.data);

    if (data.success) {
      message.success(data.message);
    }
    thunkApi.dispatch(getStores({ filters: _.filters }));

    return data;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

// reducers -> reduce to a specific state -> changes state
export const storeSlice = createSlice({
  name: "store",
  initialState,
  reducers: {
    setPageNumber: (state: IStoreInitialStateType, action: PayloadAction<number>) => {
      state.getStores.page = action.payload;
    },
    setPerPage: (state: IStoreInitialStateType, action: PayloadAction<number>) => {
      state.getStores.per_page = action.payload;
    },
    selectedStoreByIdFromLocalState: (state: IStoreInitialStateType, action: PayloadAction<number | string>) => {
      if (state.getStores.result.data.length > 0) {
        const selectedStore = state.getStores.result.data.find((x) => x.id === action.payload);

        if (selectedStore !== undefined) {
          state.singleStore.result = selectedStore;
        }
      }
    },

    resetStoreState: (state: IStoreInitialStateType, action: PayloadAction) => {
      state.addStore.success = false;
      state.updateStore.success = false;
    },
  },
  extraReducers: (builder) => {
    // get store data
    builder.addCase(getStores.pending, (state: IStoreInitialStateType) => {
      state.getStores.loading = true;
      state.getStores.success = false;
      state.getStores.error = false;
      state.getStores.unAuthorized = false;
    });
    builder.addCase(
      getStores.fulfilled,
      (state: IStoreInitialStateType, action: PayloadAction<IPagedData<IStoreDataType, IStoreFilter>>) => {
        state.getStores.success = true;
        state.getStores.result = action.payload;
        state.getStores.loading = false;
        state.getStores.error = false;
      },
    );
    builder.addCase(getStores.rejected, (state: IStoreInitialStateType, action: PayloadAction<any>) => {
      state.getStores.success = false;
      state.getStores.loading = false;
      state.getStores.result.data = [];
      state.getStores.error = action.payload.error;
      if (action.payload.error && action.payload.error.response.status === 403) {
        state.getStores.unAuthorized = true;
      }
    });

    // add store
    builder.addCase(addStore.pending, (state: IStoreInitialStateType) => {
      state.addStore.loading = true;
      state.addStore.success = false;
      state.addStore.error = null;
    });
    builder.addCase(addStore.fulfilled, (state: IStoreInitialStateType) => {
      state.addStore.success = true;
      state.addStore.loading = false;
      state.addStore.error = null;
    });
    builder.addCase(addStore.rejected, (state: IStoreInitialStateType, action: PayloadAction<any>) => {
      state.addStore.success = false;
      state.addStore.loading = false;
      state.addStore.error = action.payload && getProperErrorMessagesHendelar(action.payload);
    });

    // update store
    builder.addCase(updateStore.pending, (state: IStoreInitialStateType) => {
      state.updateStore.loading = true;
      state.updateStore.success = false;
      state.updateStore.error = null;
    });
    builder.addCase(updateStore.fulfilled, (state: IStoreInitialStateType) => {
      state.updateStore.success = true;
      state.updateStore.loading = false;
      state.updateStore.error = null;
    });
    builder.addCase(updateStore.rejected, (state: IStoreInitialStateType, action: PayloadAction<any>) => {
      state.updateStore.success = false;
      state.updateStore.loading = false;
      state.updateStore.error = action.payload && getProperErrorMessagesHendelar(action.payload);
    });
  },
});

export const { setPageNumber, selectedStoreByIdFromLocalState, resetStoreState, setPerPage } = storeSlice.actions;
export default storeSlice.reducer;
