import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import qs from "qs";
import { LOCAL_STORAGE_TOKEN_KEY } from "../consts/storage";

axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
axios.defaults.withCredentials = true;

let api_url: string = process.env.REACT_APP_API_URL || "";

const axiosConfig: AxiosRequestConfig = {
  baseURL: api_url,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
  timeout: 10000,
};
const instance = axios.create(axiosConfig);

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      window.localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
      window.location.replace("/auth/login");
    }
    return Promise.reject(error);
  },
);


const CancelToken = axios.CancelToken;
const isCancel = axios.isCancel;

const responseBody = (response: AxiosResponse) => response.data;

export async function get(url: string, filter = {}, token: string | null = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)) {
  // if (token) {
  //   axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  // }

  return await instance
    .get(url, {
      params: filter,
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: "brackets" });
      },

      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(responseBody);
}

async function post(url: string, data: any, config = { withCredentials: true }) {
  return instance.post(url, { ...data }, { ...config }).then(responseBody);
}

async function patch(url: string, data: object, config = { withCredentials: true }) {
  return instance.patch(url, { ...data }, { ...config }).then(responseBody);
}

async function put(url: string, data: any, config = { withCredentials: true }) {
  return instance.put(url, { ...data }, { ...config }).then(responseBody);
}

async function del(url: string, config = {}) {
  return await instance.delete(url, { ...config }).then(responseBody);
}

const api_request = { get, post, patch, put, del, CancelToken, isCancel };

export default api_request;
