import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { LOCAL_STORAGE_TOKEN_KEY } from "consts/storage";
import { IAuthResponseType, ILoginDataType, ISignUpDataType } from "models/Auth";
import AuthServices from "services/AuthServices";
import { getPermissionsForCurrentUser } from "../aclManage/permission/permissionSlice";

interface IAuthCommonStateType {
  success: boolean;
  loading: boolean;
  error: string | null;
}

interface IAuthInitialStateType {
  login: IAuthCommonStateType;
  signup: IAuthCommonStateType;
  result: null | IAuthResponseType;
}

interface ICommonResponseType {
  message: string | null;
  success: boolean;
}

const initialState: IAuthInitialStateType = {
  result: null,
  login: {
    success: false,
    loading: false,
    error: null,
  },
  signup: {
    success: false,
    loading: false,
    error: null,
  },
};

// login
export const login = createAsyncThunk<ICommonResponseType, ILoginDataType>("auth/login", async (value, thunkApi) => {
  try {
    const data: IAuthResponseType = await AuthServices.login(value);

    if (data.success && data.token) {
      // message.success("Successfully  login");
      window.localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, data.token);
      thunkApi.dispatch(getPermissionsForCurrentUser({ token: data.token }));
    }
    return data;
  } catch (error: unknown) {
    return thunkApi.rejectWithValue(error);
  }
});

// signup
export const signup = createAsyncThunk<ICommonResponseType, ISignUpDataType>("auth/signup", async (value, thunkApi) => {
  try {
    const data: ICommonResponseType = await AuthServices.signup(value);

    if (data) {
      message.success("Successfully  added");
      window.location.search = "";
    }
    return data;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

// reducers -> reduce to a specific state -> changes state
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuthState: (state: IAuthInitialStateType, action: PayloadAction) => {
      state.login.success = false;
      state.signup.success = false;
    },
  },
  extraReducers: (builder) => {
    // login
    builder.addCase(login.pending, (state: IAuthInitialStateType) => {
      state.login.loading = true;
      state.login.success = false;
      state.login.error = null;
    });
    builder.addCase(login.fulfilled, (state: IAuthInitialStateType, action: PayloadAction<any>) => {
      state.login.success = true;
      state.login.loading = false;
      state.result = action.payload;
      state.login.error = null;
    });
    builder.addCase(login.rejected, (state: IAuthInitialStateType, action: PayloadAction<any>) => {
      state.login.success = false;
      state.login.loading = false;
      state.login.error = action.payload?.response?.data?.message;
    });
  },
});

export const { resetAuthState } = authSlice.actions;
export default authSlice.reducer;
