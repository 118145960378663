import { IDiscountDataType, updateDiscountDataType } from "../models/Discount";
import api_request from "./ApiConfig";

class DiscountServices {
  getDiscount(perams?: object): Promise<any> {
    let url: string = "/api/v1/discount";
    return api_request.get(url, perams);
  }

  addDiscount(data: IDiscountDataType): Promise<any> {
    return api_request.post("/api/v1/discount", data);
  }
  updateDiscountById(id: string | number, values: updateDiscountDataType): Promise<any> {
    return api_request.patch(`/api/v1/discount/${id}`, values);
  }
  getDiscountById(id: string | number): Promise<any> {
    return api_request.get(`/api/v1/discount/${id}`);
  }

  deleteDiscount(id: string | number): Promise<any> {
    return api_request.del(`/api/v1/discount/${id}`);
  }
}

export default new DiscountServices();
