import { lazy } from "react";
import type { RouteType } from "./type";

const routes: RouteType[] = [
  {
    path: "/auth",
    isProtected: false,
    component: lazy(() => import("views/layouts/AuthLayout")),
    children: [
      {
        isProtected: false,
        path: "/auth/login",
        component: lazy(() => import("views/pages/Auth/Login")),
      },
    ],
  },
  {
    isProtected: true,
    path: "/",
    component: lazy(() => import("views/layouts/DashboardLayout")),
    children: [
      {
        isProtected: true,
        path: "/acl-manage",
        component: lazy(() => import("views/layouts/BlankLayout")),
        children: [
          {
            isProtected: true,
            path: "/acl-manage/overview",
            component: lazy(() => import("views/pages/AclManage/Overview")),
          },
          {
            isProtected: true,
            path: "/acl-manage/permission",
            component: lazy(() => import("views/pages/AclManage/Permission")),
          },
          {
            isProtected: true,
            path: "/acl-manage/role",
            component: lazy(() => import("views/pages/AclManage/Role")),
          },
        ],
      },
      {
        isProtected: true,
        path: "/discount-manage",
        component: lazy(() => import("views/layouts/BlankLayout")),
        children: [
          {
            isProtected: true,
            path: "/discount-manage/discount",
            component: lazy(() => import("views/pages/Discount/Discount")),
          },
          {
            isProtected: true,
            path: "/discount-manage/discount-user",
            component: lazy(() => import("views/pages/Discount/DiscountUser")),
          },
          {
            isProtected: true,
            path: "/discount-manage/discount-usage-history",
            component: lazy(() => import("views/pages/Discount/DiscountUsageHistory")),
          },
        ],
      },
      {
        isProtected: true,
        path: "/payment-manage",
        component: lazy(() => import("views/layouts/BlankLayout")),
        children: [
          {
            isProtected: true,
            path: "/payment-manage/gateway",
            component: lazy(() => import("views/pages/Payment/Gateway")),
          },
        ],
      },
      {
        isProtected: true,
        path: "/subscription-manage",
        component: lazy(() => import("views/layouts/BlankLayout")),
        children: [
          {
            isProtected: true,
            path: "/subscription-manage/service",
            component: lazy(() => import("views/pages/Subscription/Service")),
          },
          {
            isProtected: true,
            path: "/subscription-manage/plan",
            component: lazy(() => import("views/pages/Subscription/Plan")),
          },
          {
            isProtected: true,
            path: "/subscription-manage/plan-group",
            component: lazy(() => import("views/pages/Subscription/PlanGroup/PlanGroup")),
          },
          {
            isProtected: true,
            path: "/subscription-manage/plan-group-feature",
            component: lazy(() => import("views/pages/Subscription/FeatureGroup/FeatureGroup")),
          },
          {
            isProtected: true,
            path: "/subscription-manage/subscription",
            component: lazy(() => import("views/pages/Subscription/Subscription")),
          },
        ],
      },
      {
        isProtected: true,
        path: "/shop-manage",
        component: lazy(() => import("views/layouts/BlankLayout")),
        children: [
          {
            isProtected: true,
            path: "/shop-manage/shop",
            component: lazy(() => import("views/pages/ShopManage/Shop")),
          },
        ],
      },
      {
        isProtected: true,
        path: "/store-manage",
        component: lazy(() => import("views/layouts/BlankLayout")),
        children: [
          {
            isProtected: true,
            path: "/store-manage/store",
            component: lazy(() => import("views/pages/StoreManage/Store")),
          },
        ],
      },
      {
        isProtected: true,
        path: "/user-manage",
        component: lazy(() => import("views/layouts/BlankLayout")),
        children: [
          {
            isProtected: true,
            path: "/user-manage/users",
            component: lazy(() => import("views/pages/Users")),
          },
        ],
      },
    ],
  },
];

export default routes;
