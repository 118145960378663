import { Result } from "antd";
import React from "react";

const ErrorPage: React.FC<{ error?: any; errorInfo?: any }> = () => {
  return (
    <>
      <Result status="error" title={""} subTitle={"error"} />
    </>
  );
};

export default ErrorPage;
