import { IPermissionQuery, IPermissionUpdateType, IRoleCreateUpdateType, IRoleQuery, ISyncPermissionsType } from "../models/Acl";
import api_request from "./ApiConfig";

class AclService {
  static getPermissions(params: IPermissionQuery): Promise<any> {
    return api_request.get(`/api/v1/acl/permission`, params);
  }

  static getPermissionsForCurrentUser(token?: string): Promise<any> {
    return api_request.get(`/api/v1/acl/user/permissions`, {}, token);
  }

  static getGroupedPermissions(): Promise<any> {
    return api_request.get(`/api/v1/acl/group-permission`);
  }

  static getRoles(params: IRoleQuery): Promise<any> {
    return api_request.get(`/api/v1/acl/role`, params);
  }

  static roleDetail(id: number): Promise<any> {
    return api_request.get(`/api/v1/acl/role/${id}`);
  }

  static addRole(data: IRoleCreateUpdateType): Promise<any> {
    return api_request.post(`/api/v1/acl/role`, data);
  }

  static updateRole(id: number, data: IRoleCreateUpdateType): Promise<any> {
    return api_request.patch(`/api/v1/acl/role/${id}`, data);
  }

  static deleteRole(id: number): Promise<any> {
    return api_request.del(`/api/v1/acl/role/${id}`);
  }

  static syncPermissions(id: number, data: ISyncPermissionsType): Promise<any> {
    return api_request.post(`/api/v1/acl/role/${id}/sync-permissions`, data);
  }

  static syncPermission(id: number, data: { action: "add" | "remove"; permission: number }): Promise<any> {
    return api_request.post(`/api/v1/acl/role/${id}/sync-permission`, data);
  }

  static updatePermission(id: number, data: IPermissionUpdateType): Promise<any> {
    return api_request.put(`/api/v1/acl/permission/${id}`, data);
  }
}

export default AclService;
