import { IServiceInputFilter } from "models/Service.d";
import { linksInitialValue, metaInitialValue } from "redux/reducers/common";

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";

import { getProperErrorMessagesHendelar } from "utils/errorHandelar";

import { IPagedData } from "models/Common";
import { IServiceApiResponseDataType, IServiceDataType, IServiceResponseFilter } from "models/Service";
import ProductsService from "services/ProductsService";

interface IServiceCommonInitialStateType {
  success: boolean;
  loading: boolean;
  error: {
    validationErrors:
      | {
          label: string;
          message: string;
        }[]
      | [];
    othersErrors: {};
  } | null;
}

interface IServiceInitialStateType {
  addService: IServiceCommonInitialStateType;
  editService: IServiceCommonInitialStateType;
  deleteService: {
    success: boolean;
    loading: boolean;
    error: null | object;
  };
  getServices: {
    result: IPagedData<IServiceApiResponseDataType, IServiceResponseFilter>;
    success: boolean;
    loading: boolean;
    error: any;
    unAuthorized: boolean;
    page: number | null;
    per_page: number | null;
  };
  singleService: {
    result: IServiceApiResponseDataType | null;
    success: boolean;
    loading: boolean;
    error: any;
  };
  gatewayId: number | string;
}

type addArgumentsDataType = {
  data: IServiceDataType;

  page?: string | number | null;
};
type updateArgumentsDataType = {
  data: IServiceDataType;
  filters: object;
  target_product_id: string | number;
};

type addServiceApiReturnType = {
  message: string | null;
  success: boolean;
  data: IServiceApiResponseDataType;
};
type updateServiceApiReturnType = {
  message: string | null;
  success: boolean;
};

const initialState: IServiceInitialStateType = {
  addService: {
    success: false,
    loading: false,
    error: {
      validationErrors: [],
      othersErrors: {},
    },
  },
  editService: {
    success: false,
    loading: false,
    error: {
      validationErrors: [],
      othersErrors: {},
    },
  },
  deleteService: {
    success: false,
    loading: false,
    error: null,
  },
  getServices: {
    result: {
      data: [],
      links: linksInitialValue,
      meta: metaInitialValue,
      filters: {},
      success: false,
      message: "",
    },
    success: false,
    loading: false,
    error: null,
    unAuthorized: false,
    page: null,
    per_page: null,
  },
  singleService: {
    result: null,
    success: false,
    loading: false,
    error: null,
  },
  gatewayId: "",
};

// get all services list

export const getServices = createAsyncThunk<
  IPagedData<IServiceApiResponseDataType, IServiceResponseFilter>,
  { filters?: IServiceInputFilter }
>("service/serviceList", async (_, thunkApi) => {
  try {
    const data: IPagedData<IServiceApiResponseDataType, IServiceResponseFilter> = await ProductsService.getServices(_.filters);
    return data;
  } catch (error) {
    const modifyError: any = error;

    return thunkApi.rejectWithValue(modifyError);
  }
});

// get single service by ID

export const getSingleServiceById = createAsyncThunk<
  IPagedData<IServiceApiResponseDataType, IServiceResponseFilter>,
  { id: string | number }
>("service/singleService", async (_, thunkApi) => {
  try {
    return await ProductsService.getServiceById(_.id);
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

// add  services
export const addService = createAsyncThunk<addServiceApiReturnType, addArgumentsDataType>("service/addedService", async (_, thunkApi) => {
  try {
    const data: addServiceApiReturnType = await ProductsService.addServices(_.data);
    if (data.success) {
      message.success(data.message);
    }
    thunkApi.dispatch(getServices({ filters: { page: 1 } }));

    return data;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

// update discount async thunk

export const updateService = createAsyncThunk<updateServiceApiReturnType, updateArgumentsDataType>(
  "service/updateService",
  async (_, thunkApi) => {
    try {
      const data: updateServiceApiReturnType = await ProductsService.updateServicesById(_.target_product_id, _.data);

      if (data.success) {
        message.success(data.message);
      }
      thunkApi.dispatch(getServices({ filters: _.filters }));

      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

// reducers -> reduce to a specific state -> changes state
export const serviceProductSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    setPageNumber: (state: IServiceInitialStateType, action: PayloadAction<number>) => {
      state.getServices.page = action.payload;
    },
    setPerPage: (state: IServiceInitialStateType, action: PayloadAction<number>) => {
      state.getServices.per_page = action.payload;
    },
    setSelectedServiceByIdFromLocalState: (state: IServiceInitialStateType, action: PayloadAction<number | string>) => {
      const selectedService = state.getServices.result.data.find((x) => x.id === action.payload);

      if (selectedService) state.singleService.result = selectedService;
    },
    setSeletedGatewayId: (state: IServiceInitialStateType, action: PayloadAction<number | string>) => {
      state.gatewayId = action.payload;
    },
    resetServiceState: (state: IServiceInitialStateType) => {
      state.addService.success = false;
      state.editService.success = false;
      state.getServices.success = false;
      state.getServices.loading = false;
    },
  },
  extraReducers: (builder) => {
    // get discount data
    builder.addCase(getServices.pending, (state: IServiceInitialStateType) => {
      state.getServices.loading = true;
      state.getServices.success = false;
      state.getServices.error = false;
      state.getServices.unAuthorized = false;
    });
    builder.addCase(
      getServices.fulfilled,
      (state: IServiceInitialStateType, action: PayloadAction<IPagedData<IServiceApiResponseDataType, IServiceResponseFilter>>) => {
        state.getServices.success = true;
        state.getServices.result = action.payload;
        state.getServices.loading = false;
        state.getServices.error = false;
      },
    );
    builder.addCase(getServices.rejected, (state: IServiceInitialStateType, action: PayloadAction<any>) => {
      state.getServices.success = false;
      state.getServices.result.data = [];
      state.getServices.loading = false;
      state.getServices.error = action.payload.error;
      if (action.payload.error && action.payload.error.response.status === 403) {
        state.getServices.unAuthorized = true;
      }
    });
    // get single service By ID
    builder.addCase(getSingleServiceById.pending, (state: IServiceInitialStateType) => {
      state.singleService.loading = true;
      state.singleService.success = false;
      state.singleService.error = false;
    });
    builder.addCase(
      getSingleServiceById.fulfilled,
      (state: IServiceInitialStateType, action: PayloadAction<IPagedData<IServiceApiResponseDataType, IServiceResponseFilter>>) => {
        state.singleService.success = true;
        state.singleService.result = action.payload.data[0];
        state.singleService.loading = false;
        state.singleService.error = false;
      },
    );
    builder.addCase(getSingleServiceById.rejected, (state: IServiceInitialStateType, action: PayloadAction<any>) => {
      state.singleService.success = false;
      state.singleService.result = null;
      state.singleService.loading = false;
      state.singleService.error = action.payload.error;
    });

    // add Service
    builder.addCase(addService.pending, (state: IServiceInitialStateType) => {
      state.addService.loading = true;
      state.addService.success = false;
      state.addService.error = null;
    });
    builder.addCase(addService.fulfilled, (state: IServiceInitialStateType) => {
      state.addService.success = true;
      state.addService.loading = false;
      state.addService.error = null;
    });
    builder.addCase(addService.rejected, (state: IServiceInitialStateType, action: PayloadAction<any>) => {
      state.addService.success = false;
      state.addService.loading = false;
      state.addService.error = action.payload && getProperErrorMessagesHendelar(action.payload);

      //  if (action.payload?.error?.response.status === 422) {
      //   state.getService.error =getProperErrorMessages(action.payload.error) ;
      // };
    });

    // update Service
    builder.addCase(updateService.pending, (state: IServiceInitialStateType) => {
      state.editService.loading = true;
      state.editService.success = false;
      state.editService.error = null;
    });
    builder.addCase(updateService.fulfilled, (state: IServiceInitialStateType) => {
      state.editService.success = true;
      state.editService.loading = false;
      state.editService.error = null;
    });
    builder.addCase(updateService.rejected, (state: IServiceInitialStateType, action: PayloadAction<any>) => {
      state.editService.success = false;
      state.editService.loading = false;
      state.editService.error = action.payload && getProperErrorMessagesHendelar(action.payload);
    });
  },
});

export const { setPageNumber, setSelectedServiceByIdFromLocalState, setPerPage, setSeletedGatewayId, resetServiceState } =
  serviceProductSlice.actions;
export default serviceProductSlice.reducer;
