import { IShopCreateType, IShopQuery, IShopUpdateType } from "models/Shop";
import api_request from "./ApiConfig";

class ShopService {
  static getShops(params?: IShopQuery): Promise<any> {
    return api_request.get(`/api/v1/shop`, params);
  }

  static addShop(data: IShopCreateType): Promise<any> {
    return api_request.post(`/api/v1/shop`, data);
  }

  static updateShop(id: number, data: IShopUpdateType): Promise<any> {
    return api_request.patch(`/api/v1/shop/${id}`, data);
  }
}

export default ShopService;
