import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { IRoleCreateUpdateType, IRoleDataType, IRoleFilter, IRoleQuery } from "models/Acl";
import { IPagedData } from "models/Common.d";
import { ICommonResponseType, ICommonStateType, linksInitialValue, metaInitialValue } from "redux/reducers/common";
import AclService from "services/AclService";
import { getProperErrorMessagesHendelar } from "utils/errorHandelar";

interface IRoleInitialStateType {
  addRole: ICommonStateType;
  updateRole: ICommonStateType;
  deleteRole: ICommonStateType;
  syncPermissions: ICommonStateType;
  syncPermission: ICommonStateType;
  getRoles: {
    result: IPagedData<IRoleDataType, IRoleFilter>;
    success: boolean;
    loading: boolean;
    error: any;
    unAuthorized: boolean;
    page: number | null;
    per_page: number | null;
  };
  roleDetail: {
    result: IRoleDataType | null;
    success: boolean;
    loading: boolean;
    error: any;
  };
}

type updateArgumentsDataType = {
  data: IRoleCreateUpdateType;
  id: number;
  filters: IRoleQuery;
};

type syncPermissionsArgumentDataType = {
  permissions: number[];
  id: number;
};

interface IRoleDetailResponseType {
  data: IRoleDataType;
  message: string | null;
  success: boolean;
}

const initialState: IRoleInitialStateType = {
  addRole: {
    success: false,
    loading: false,
    error: {
      validationErrors: [],
      othersErrors: {},
    },
  },
  updateRole: {
    success: false,
    loading: false,
    error: {
      validationErrors: [],
      othersErrors: {},
    },
  },
  deleteRole: {
    success: false,
    loading: false,
    error: {
      validationErrors: [],
      othersErrors: {},
    },
  },
  getRoles: {
    result: {
      data: [],
      links: linksInitialValue,
      meta: metaInitialValue,
      filters: {},
      success: false,
      message: "",
    },
    success: false,
    loading: false,
    error: null,
    unAuthorized: false,
    page: null,
    per_page: null,
  },
  roleDetail: {
    success: false,
    result: null,
    loading: false,
    error: {
      validationErrors: [],
      othersErrors: {},
    },
  },
  syncPermissions: {
    success: false,
    loading: false,
    error: {
      validationErrors: [],
      othersErrors: {},
    },
  },
  syncPermission: {
    success: false,
    loading: false,
    error: {
      validationErrors: [],
      othersErrors: {},
    },
  },
};

// get all roles
export const getRoles = createAsyncThunk<IPagedData<IRoleDataType, IRoleFilter>, { filters: IRoleQuery }>(
  "role/getRoles",
  async (_, thunkApi) => {
    try {
      const data: IPagedData<IRoleDataType, IRoleFilter> = await AclService.getRoles(_.filters);
      return data;
    } catch (error) {
      const modifyError: any = error;

      return thunkApi.rejectWithValue(modifyError);
    }
  },
);

export const roleDetail = createAsyncThunk<IRoleDataType, { id: number }>("role/getRole", async (_, thunkApi) => {
  try {
    const data: IRoleDetailResponseType = await AclService.roleDetail(_.id);
    return data.data;
  } catch (error) {
    const modifyError: any = error;

    return thunkApi.rejectWithValue(modifyError);
  }
});

// add role
export const addRole = createAsyncThunk<ICommonResponseType, IRoleCreateUpdateType>("role/addRole", async (value, thunkApi) => {
  try {
    const data: ICommonResponseType = await AclService.addRole(value);
    if (data.success) {
      message.success(data.message);
    }
    thunkApi.dispatch(getRoles({ filters: {} }));
    return data;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

// update role async thunk
export const updateRole = createAsyncThunk<ICommonResponseType, updateArgumentsDataType>("role/updateRole", async (_, thunkApi) => {
  try {
    const data: ICommonResponseType = await AclService.updateRole(_.id, _.data);

    if (data.success) {
      message.success(data.message);
    }
    thunkApi.dispatch(getRoles({ filters: _.filters }));

    return data;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

// update role async thunk
export const syncPermissions = createAsyncThunk<ICommonResponseType, syncPermissionsArgumentDataType>(
  "role/syncPermissions",
  async (_, thunkApi) => {
    try {
      const data: ICommonResponseType = await AclService.syncPermissions(_.id, { permissions: _.permissions });

      if (data.success) {
        message.success(data.message);
      }
      thunkApi.dispatch(getRoles({ filters: {} }));

      return data;
    } catch (error) {
      // @ts-ignore
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const syncPermission = createAsyncThunk<ICommonResponseType, { id: number; action: "add" | "remove"; permission: number }>(
  "role/syncPermission",
  async (_, thunkApi) => {
    try {
      const data: ICommonResponseType = await AclService.syncPermission(_.id, { action: _.action, permission: _.permission });

      if (data.success) {
        message.success(data.message);
      }
      thunkApi.dispatch(getRoles({ filters: {} }));

      return data;
    } catch (error) {
      // @ts-ignore
      return thunkApi.rejectWithValue(error);
    }
  },
);

// delete role
export const deleteRole = createAsyncThunk<ICommonResponseType, { id: number; filters: IRoleQuery }>("role/delete", async (_, thunkApi) => {
  try {
    const data: ICommonResponseType = await AclService.deleteRole(_.id);

    if (data.success) {
      message.success(data.message);
    }
    thunkApi.dispatch(getRoles({ filters: _.filters }));

    return data;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

// reducers -> reduce to a specific state -> changes state
export const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    setPageNumber: (state: IRoleInitialStateType, action: PayloadAction<number>) => {
      state.getRoles.page = action.payload;
    },
    setPerPage: (state: IRoleInitialStateType, action: PayloadAction<number>) => {
      state.getRoles.per_page = action.payload;
    },
    resetRoleState: (state: IRoleInitialStateType) => {
      state.roleDetail.success = false;
      state.addRole.success = false;
      state.updateRole.success = false;
      state.deleteRole.success = false;
    },
  },
  extraReducers: (builder) => {
    // get role data
    builder.addCase(getRoles.pending, (state: IRoleInitialStateType) => {
      state.getRoles.loading = true;
      state.getRoles.success = false;
      state.getRoles.error = false;
      state.getRoles.unAuthorized = false;
    });
    builder.addCase(getRoles.fulfilled, (state: IRoleInitialStateType, action: PayloadAction<IPagedData<IRoleDataType, IRoleFilter>>) => {
      state.getRoles.success = true;
      state.getRoles.result = action.payload;
      state.getRoles.loading = false;
      state.getRoles.error = false;
    });
    builder.addCase(getRoles.rejected, (state: IRoleInitialStateType, action: PayloadAction<any>) => {
      state.getRoles.success = false;
      state.getRoles.loading = false;
      state.getRoles.result.data = [];
      state.getRoles.error = action.payload.error;
      if (action.payload.error && action.payload.error.response.status === 403) {
        state.getRoles.unAuthorized = true;
      }
    });

    // role detail

    builder.addCase(roleDetail.pending, (state: IRoleInitialStateType) => {
      state.roleDetail.loading = true;
      state.roleDetail.success = false;
      state.roleDetail.error = null;
    });
    builder.addCase(roleDetail.fulfilled, (state: IRoleInitialStateType, action: PayloadAction<IRoleDataType>) => {
      state.roleDetail.success = true;
      state.roleDetail.loading = false;
      state.roleDetail.error = null;
      state.roleDetail.result = action.payload;
    });
    builder.addCase(roleDetail.rejected, (state: IRoleInitialStateType, action: PayloadAction<any>) => {
      state.roleDetail.success = false;
      state.roleDetail.loading = false;
      state.roleDetail.error = action.payload && getProperErrorMessagesHendelar(action.payload);
    });

    // add role
    builder.addCase(addRole.pending, (state: IRoleInitialStateType) => {
      state.addRole.loading = true;
      state.addRole.success = false;
      state.addRole.error = null;
    });
    builder.addCase(addRole.fulfilled, (state: IRoleInitialStateType) => {
      state.addRole.success = true;
      state.addRole.loading = false;
      state.addRole.error = null;
    });
    builder.addCase(addRole.rejected, (state: IRoleInitialStateType, action: PayloadAction<any>) => {
      state.addRole.success = false;
      state.addRole.loading = false;
      state.addRole.error = action.payload && getProperErrorMessagesHendelar(action.payload);
    });

    // update role
    builder.addCase(updateRole.pending, (state: IRoleInitialStateType) => {
      state.updateRole.loading = true;
      state.updateRole.success = false;
      state.updateRole.error = null;
    });
    builder.addCase(updateRole.fulfilled, (state: IRoleInitialStateType) => {
      state.updateRole.success = true;
      state.updateRole.loading = false;
      state.updateRole.error = null;
    });
    builder.addCase(updateRole.rejected, (state: IRoleInitialStateType, action: PayloadAction<any>) => {
      state.updateRole.success = false;
      state.updateRole.loading = false;
      state.updateRole.error = action.payload && getProperErrorMessagesHendelar(action.payload);
    });

    // delete role
    builder.addCase(deleteRole.pending, (state: IRoleInitialStateType) => {
      state.deleteRole.loading = true;
      state.deleteRole.success = false;
      state.deleteRole.error = null;
    });
    builder.addCase(deleteRole.fulfilled, (state: IRoleInitialStateType) => {
      state.deleteRole.success = true;
      state.deleteRole.loading = false;
      state.deleteRole.error = null;
    });
    builder.addCase(deleteRole.rejected, (state: IRoleInitialStateType, action: PayloadAction<any>) => {
      state.deleteRole.success = false;
      state.deleteRole.loading = false;
      state.deleteRole.error = action.payload && getProperErrorMessagesHendelar(action.payload);
    });

    // Sync permissions
    builder.addCase(syncPermissions.pending, (state: IRoleInitialStateType) => {
      state.syncPermissions.loading = true;
      state.syncPermissions.success = false;
      state.syncPermissions.error = null;
    });
    builder.addCase(syncPermissions.fulfilled, (state: IRoleInitialStateType) => {
      state.syncPermissions.success = true;
      state.syncPermissions.loading = false;
      state.syncPermissions.error = null;
    });
    builder.addCase(syncPermissions.rejected, (state: IRoleInitialStateType, action: PayloadAction<any>) => {
      state.syncPermissions.success = false;
      state.syncPermissions.loading = false;
      state.syncPermissions.error = action.payload && getProperErrorMessagesHendelar(action.payload);
    });

    // Sync permissions
    builder.addCase(syncPermission.pending, (state: IRoleInitialStateType) => {
      state.syncPermission.loading = true;
      state.syncPermission.success = false;
      state.syncPermission.error = null;
    });
    builder.addCase(syncPermission.fulfilled, (state: IRoleInitialStateType) => {
      state.syncPermission.success = true;
      state.syncPermission.loading = false;
      state.syncPermission.error = null;
    });
    builder.addCase(syncPermission.rejected, (state: IRoleInitialStateType, action: PayloadAction<any>) => {
      state.syncPermission.success = false;
      state.syncPermission.loading = false;
      state.syncPermission.error = action.payload && getProperErrorMessagesHendelar(action.payload);
    });
  },
});

export const { setPageNumber, resetRoleState, setPerPage } = roleSlice.actions;
export default roleSlice.reducer;
