import { IPlanFeatureUpdateDataType, IPlanType } from "models/Plan";

import { IPlanFeatureCreateDataType } from "./../models/Plan.d";
import api_request from "./ApiConfig";

class PlanService {
  getPlans(gateway_id: number | string | null = null, parems?: object): Promise<any> {
    let url: string = gateway_id ? `/api/v1/plan?gateway_id=${gateway_id}` : `/api/v1/plan`;

    return api_request.get(url, parems);
  }

  addPlan(data: IPlanType): Promise<any> {
    return api_request.post(`/api/v1/plan`, data);
  }

  activePlan(target_plan_id: number | string): Promise<any> {
    return api_request.patch(`/api/v1/plan/${target_plan_id}/activate`, {});
  }
  deActivePlan(target_plan_id: number | string): Promise<any> {
    return api_request.patch(`/api/v1/plan/${target_plan_id}/deactivate`, {});
  }
  getPlanFeaturesByPlanId(plan_id: number | string, parems?: object): Promise<any> {
    return api_request.get(`/api/v1/plan-feature?plan_id=${plan_id}`, parems);
  }

  createPlanFeature(data: IPlanFeatureCreateDataType): Promise<any> {
    return api_request.post(`/api/v1/plan-feature`, data);
  }

  updatePlanFeature(data: IPlanFeatureUpdateDataType, id: string | number): Promise<any> {
    return api_request.patch(`/api/v1/plan-feature/${id}`, data);
  }
  deletePlanFeature(id: string | number): Promise<any> {
    return api_request.del(`/api/v1/plan-feature/${id}`);
  }

  syncPlanFeatures(ids: any): Promise<any> {
    return  api_request.put(`/api/v1/plan-feature/sync-plan-feature`, ids)
  }
}

export default new PlanService();
