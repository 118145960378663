export const DATE_TIME_FORMAT_2 = "DD/MM/YYYY hh:mm a";

export const SUBSCRIPTION_PLAN_QUERY = {
  MONTHLY_INTERVAL_UNIT: "month",
  MONTHLY_INTERVAL_COUNT: 1,
  YEARLY_INTERVAL_UNIT: "month",
  YEARLY_INTERVAL_COUNT: 12,
};

export const moveupAppURL = `${process.env.REACT_APP_MOVEUP_APP_URL}`
export const remoteFavIconUrl = `${process.env.REACT_APP_STATIC_HOST_URL}/assets/images/logo/icon/icon-48x48-dark.png`
export const mainLogoUrl = `${process.env.REACT_APP_STATIC_HOST_URL}/assets/images/logo/moveup-dark.png`

export const allUserDiscount = {
  label: 'ALL',
  value: 0,
}
