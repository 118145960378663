import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IModelList } from "models/Common";
import { IFeatureGroupsType } from "models/PlanGroup";
import GenericService from "services/GenericService";

interface IModelInitialStateType {
  getModels: {
    result: IModelList;
    success: boolean;
    loading: boolean;
    error: any;
    unAuthorized: boolean;
  };
  feature_groups: {
    result: IFeatureGroupsType[];
    success: boolean;
    loading: boolean;
    error: any;
    unAuthorized: boolean;
  };
}

const initialState: IModelInitialStateType = {
  getModels: {
    result: {
      shops: [],
      stores: [],
      store_slugs: [],
      shop_slugs: [],
    },
    success: false,
    loading: false,
    error: null,
    unAuthorized: false,
  },
  feature_groups: {
    result: [] as IFeatureGroupsType[],
    success: false,
    loading: false,
    error: null,
    unAuthorized: false,
  },
};

// get all model list

export const getModels = createAsyncThunk<IModelList>("store/get-models", async (_, thunkApi) => {
  try {
    const data: IModelList = await GenericService.getModels();
    return data;
  } catch (error) {
    const modifyError: any = error;

    return thunkApi.rejectWithValue(modifyError);
  }
});

// get all feature list

export const getFeatures = createAsyncThunk<IFeatureGroupsType[]>("generic/get-feature", async (_, thunkApi) => {
  try {
    const data: { feature_groups: IFeatureGroupsType[] } = await GenericService.getFeatures();
    return data.feature_groups;
  } catch (error) {
    const modifyError: any = error;
    console.log(error);
    return thunkApi.rejectWithValue(modifyError);
  }
});

// reducers -> reduce to a specific state -> changes state
export const genericSlice = createSlice({
  name: "generic",
  initialState,
  reducers: {
    resetStoreState: (state: IModelInitialStateType, action: PayloadAction) => {
      state.getModels.success = false;
    },
  },
  extraReducers: (builder) => {
    // get models
    builder.addCase(getModels.pending, (state: IModelInitialStateType) => {
      state.getModels.loading = true;
      state.getModels.success = false;
      state.getModels.error = false;
      state.getModels.unAuthorized = false;
    });
    builder.addCase(getModels.fulfilled, (state: IModelInitialStateType, action: PayloadAction<any>) => {
      state.getModels.success = true;
      state.getModels.result = action.payload;
      state.getModels.loading = false;
      state.getModels.error = false;
    });
    builder.addCase(getModels.rejected, (state: IModelInitialStateType, action: PayloadAction<any>) => {
      state.getModels.success = false;
      state.getModels.loading = false;
      state.getModels.result = { shops: [], stores: [], store_slugs: [], shop_slugs: [] };
      state.getModels.error = action.payload.error;
      if (action.payload.error && action.payload.error.response.status === 403) {
        state.getModels.unAuthorized = true;
      }
    });

    // get features
    builder.addCase(getFeatures.pending, (state: IModelInitialStateType) => {
      state.feature_groups.loading = true;
      state.feature_groups.success = false;
      state.feature_groups.result = [];

      state.feature_groups.error = false;
      state.feature_groups.unAuthorized = false;
    });
    builder.addCase(getFeatures.fulfilled, (state: IModelInitialStateType, action: PayloadAction<any>) => {
      state.feature_groups.success = true;
      state.feature_groups.result = action.payload;
      state.feature_groups.loading = false;
      state.feature_groups.error = false;
    });
    builder.addCase(getFeatures.rejected, (state: IModelInitialStateType, action: PayloadAction<any>) => {
      state.feature_groups.success = false;
      state.feature_groups.loading = false;
      state.feature_groups.result = [];
      state.feature_groups.error = action.payload.error;
      if (action.payload.error && action.payload?.error?.response?.status === 403) {
        state.feature_groups.unAuthorized = true;
      }
    });
  },
});

export default genericSlice.reducer;
