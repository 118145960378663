import api_request from "./ApiConfig";

class PlanGroupService {
  getPlanGroups(parems?: object): Promise<any> {
    return api_request.get(`/api/v1/plan-group`, parems);
  }

  addPlanGroup(planGroupName: string): Promise<any> {
    return api_request.post(`/api/v1/plan-group`, { name: planGroupName });
  }
  attachPlan(planGroupId: string | number, data: { plan_id: number; is_primary: boolean }): Promise<any> {
    return api_request.patch(`/api/v1/plan-group/${planGroupId}/attach-plan`, data);
  }

  updatePlanGroup(planGroupName: string, planGroupId: string | number): Promise<any> {
    return api_request.patch(`/api/v1/plan-group/${planGroupId}`, { name: planGroupName });
  }
  updatePlanGroupFeature(planGroupId: string | number, feature_groups: string): Promise<any> {
    return api_request.patch(`/api/v1/plan-group/${planGroupId}/feature-group-update`, { feature_groups });
  }
  deletePlanGroup(planGroupId: string | number): Promise<any> {
    return api_request.del(`/api/v1/plan-group/${planGroupId}`);
  }
}

export default new PlanGroupService();
