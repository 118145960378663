import { linksInitialValue, metaInitialValue } from "redux/reducers/common";

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { IPagedData } from "models/Common.d";
import { IPlanInputFilter } from "models/Plan.d";

import { IFeatureGroupsType, IPlanGroupType, IPlanGroupInputFilter, IPlanGroupResponseFilter } from "models/PlanGroup";
import PlanGroupService from "services/PlanGroupService";
import { getProperErrorMessagesHendelar } from "utils/errorHandelar";

interface IPlanGroupCommonInitialStateType {
  success: boolean;
  loading: boolean;
  error: {
    validationErrors:
      | {
          label: string;
          message: string;
        }[]
      | [];
    othersErrors: {};
  } | null;
}

interface IPlanGroupInitialStateType {
  addPlanGroup: IPlanGroupCommonInitialStateType;
  attachPlan: IPlanGroupCommonInitialStateType;
  updatePlanGroup: IPlanGroupCommonInitialStateType;
  updatePlanGroupFeatures: IPlanGroupCommonInitialStateType;
  deletePlanGroup: IPlanGroupCommonInitialStateType;

  getPlanGroups: {
    result: IPagedData<IPlanGroupType, IPlanGroupResponseFilter>;
    success: boolean;
    loading: boolean;
    error: any;
    unAuthorized: boolean;
    page: number | null;
    per_page: number | null;
  };
  planGroupDetails: IPlanGroupType | null;
}

type apiReturnCommonType = {
  message: string | null;
  success: boolean;
};

const initialState: IPlanGroupInitialStateType = {
  addPlanGroup: {
    success: false,
    loading: false,
    error: {
      validationErrors: [],
      othersErrors: {},
    },
  },
  attachPlan: {
    success: false,
    loading: false,
    error: {
      validationErrors: [],
      othersErrors: {},
    },
  },

  updatePlanGroup: {
    loading: false,
    success: false,
    error: {
      validationErrors: [],
      othersErrors: {},
    },
  },
  updatePlanGroupFeatures: {
    loading: false,
    success: false,
    error: {
      validationErrors: [],
      othersErrors: {},
    },
  },
  deletePlanGroup: {
    loading: false,
    success: false,
    error: {
      validationErrors: [],
      othersErrors: {},
    },
  },
  getPlanGroups: {
    result: {
      data: [],
      links: linksInitialValue,
      meta: metaInitialValue,
      filters: {},
      success: false,
      message: "",
    },
    success: false,
    loading: false,
    error: null,
    unAuthorized: false,
    page: null,
    per_page: null,
  },

  planGroupDetails: null,
};

// get all plan group list

export const getPlanGroups = createAsyncThunk<IPagedData<IPlanGroupType, IPlanGroupResponseFilter>, { filters?: IPlanInputFilter }>(
  "planGroup/List",
  async (_, thunkApi) => {
    try {
      const data: IPagedData<IPlanGroupType, IPlanGroupResponseFilter> = await PlanGroupService.getPlanGroups(_.filters);
      return data;
    } catch (error) {
      const modifyError: any = error;

      return thunkApi.rejectWithValue(modifyError);
    }
  },
);

// add  plan group
export const addPlanGroup = createAsyncThunk<apiReturnCommonType, { name: string }>("planGroup/createPlanGroup", async (_, thunkApi) => {
  try {
    const data: apiReturnCommonType = await PlanGroupService.addPlanGroup(_.name);

    if (data.success) {
      message.success(data.message);
    }
    // window.location.search = "";
    thunkApi.dispatch(getPlanGroups({ filters: { page: 1 } }));

    return data;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

// add  attachPlan
export const attachPlan = createAsyncThunk<
  apiReturnCommonType,
  { planGroupId: number | string; data: { plan_id: number; is_primary: boolean } }
>("planGroup/attach_plan", async (_, thunkApi) => {
  try {
    const data: apiReturnCommonType = await PlanGroupService.attachPlan(_.planGroupId, _.data);

    if (data.success) {
      message.success(data.message);
    }
    // window.location.search = "";
    thunkApi.dispatch(getPlanGroups({ filters: { page: 1 } }));

    return data;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

// update plan group
export const updatePlanGroup = createAsyncThunk<
  apiReturnCommonType,
  { filters?: IPlanGroupInputFilter; planGroupId: string | number; name: string }
>("planGroup/update_plan_group", async (_, thunkApi) => {
  try {
    const data: apiReturnCommonType = await PlanGroupService.updatePlanGroup(_.name, _.planGroupId);

    if (data.success) {
      message.success(data.message);
    }
    thunkApi.dispatch(getPlanGroups({ filters: _.filters }));

    return data;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});
// update plan group features
export const updatePlanGroupFeatures = createAsyncThunk<apiReturnCommonType, { planGroupId: string | number; feature_groups: string }>(
  "planGroup/update_plan_group_features",
  async (_, thunkApi) => {
    try {
      const data: apiReturnCommonType = await PlanGroupService.updatePlanGroupFeature(_.planGroupId, _.feature_groups);

      if (data.success) {
        message.success(data.message);

        thunkApi.dispatch(getPlanGroups({}));
      }

      return data;
    } catch (error) {
      message.error("Something went wrong try again later!");
      return thunkApi.rejectWithValue(error);
    }
  },
);
// delete plan group

export const deletePlanGroup = createAsyncThunk<apiReturnCommonType, { filters?: IPlanGroupInputFilter; planGroupId: string | number }>(
  "plan/delete_plan_feature",
  async (_, thunkApi) => {
    try {
      const data: apiReturnCommonType = await PlanGroupService.deletePlanGroup(_.planGroupId);

      if (data.success) {
        message.success(data.message);
      }

      thunkApi.dispatch(getPlanGroups({ filters: _.filters }));

      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

// reducers -> reduce to a specific state -> changes state
export const planGroupSlice = createSlice({
  name: "plan_group",
  initialState,
  reducers: {
    setSelectedPlanGroupByIdFromLocalState: (state: IPlanGroupInitialStateType, action: PayloadAction<number | string>) => {
      const planGroupDetails = state.getPlanGroups.result.data.find((x) => Number(x.id) === Number(action.payload));
      if (planGroupDetails) {
        let featureDetails = {
          ...planGroupDetails,
          feature_groups: typeof planGroupDetails.feature_groups === "string" ? JSON.parse(planGroupDetails.feature_groups) : [],
        };

        state.planGroupDetails = featureDetails;
      }
    },

    updateGroupFeatureValueBySlug: (state: IPlanGroupInitialStateType, action: PayloadAction<{ slug: string; value: string }>) => {
      if (state.planGroupDetails && Array.isArray(state.planGroupDetails?.feature_groups)) {
        const updateFeatures = state.planGroupDetails.feature_groups.map((feature, i1) => {
          feature.features.forEach((x, i2) => {
            if (x.slug === action.payload.slug) {
              x.value = action.payload.value;
            }
          });

          return feature;
        });

        state.planGroupDetails = { ...state.planGroupDetails, feature_groups: updateFeatures };
      } else {
      }
    },
    attachPlanGroupFeature: (state: IPlanGroupInitialStateType, action: PayloadAction<IFeatureGroupsType[]>) => {
      if (state.planGroupDetails && Array.isArray(action.payload)) {
        state.planGroupDetails = { ...state.planGroupDetails, feature_groups: action.payload };
      } else {
      }
    },
  },
  extraReducers: (builder) => {
    // get plan  group all data
    builder.addCase(getPlanGroups.pending, (state: IPlanGroupInitialStateType) => {
      state.getPlanGroups.loading = true;
      state.getPlanGroups.success = false;
      state.getPlanGroups.error = false;
      state.getPlanGroups.unAuthorized = false;
    });
    builder.addCase(
      getPlanGroups.fulfilled,
      (state: IPlanGroupInitialStateType, action: PayloadAction<IPagedData<IPlanGroupType, IPlanGroupResponseFilter>>) => {
        state.getPlanGroups.success = true;
        state.getPlanGroups.result = action.payload;
        state.getPlanGroups.loading = false;
        state.getPlanGroups.error = false;
      },
    );
    builder.addCase(getPlanGroups.rejected, (state: IPlanGroupInitialStateType, action: PayloadAction<any>) => {
      state.getPlanGroups.success = false;
      state.getPlanGroups.result.data = [];
      state.getPlanGroups.loading = false;
      state.getPlanGroups.error = action.payload.error;
      if (action.payload.error && action.payload.error.response.status === 403) {
        state.getPlanGroups.unAuthorized = true;
      }
    });

    // add plan group
    builder.addCase(addPlanGroup.pending, (state: IPlanGroupInitialStateType) => {
      state.addPlanGroup.loading = true;
      state.addPlanGroup.success = false;
      state.addPlanGroup.error = null;
    });
    builder.addCase(addPlanGroup.fulfilled, (state: IPlanGroupInitialStateType) => {
      state.addPlanGroup.success = true;
      state.addPlanGroup.loading = false;
      state.addPlanGroup.error = null;
    });
    builder.addCase(addPlanGroup.rejected, (state: IPlanGroupInitialStateType, action: PayloadAction<any>) => {
      state.addPlanGroup.success = false;
      state.addPlanGroup.loading = false;
      state.addPlanGroup.error = action.payload && getProperErrorMessagesHendelar(action.payload);
    });

    //  attach plan group
    builder.addCase(attachPlan.pending, (state: IPlanGroupInitialStateType) => {
      state.attachPlan.loading = true;
      state.attachPlan.success = false;
      state.attachPlan.error = null;
    });
    builder.addCase(attachPlan.fulfilled, (state: IPlanGroupInitialStateType) => {
      state.attachPlan.success = true;
      state.attachPlan.loading = false;
      state.attachPlan.error = null;
    });
    builder.addCase(attachPlan.rejected, (state: IPlanGroupInitialStateType, action: PayloadAction<any>) => {
      state.attachPlan.success = false;
      state.attachPlan.loading = false;
      state.attachPlan.error = action.payload && getProperErrorMessagesHendelar(action.payload);
    });

    // update plan group
    builder.addCase(updatePlanGroup.pending, (state: IPlanGroupInitialStateType) => {
      state.updatePlanGroup.loading = true;
      state.updatePlanGroup.success = false;
      state.updatePlanGroup.error = null;
    });
    builder.addCase(updatePlanGroup.fulfilled, (state: IPlanGroupInitialStateType) => {
      state.updatePlanGroup.loading = false;
      state.updatePlanGroup.success = true;
      state.updatePlanGroup.error = null;
    });
    builder.addCase(updatePlanGroup.rejected, (state: IPlanGroupInitialStateType, action: PayloadAction<any>) => {
      state.updatePlanGroup.loading = false;
      state.updatePlanGroup.success = true;
      state.updatePlanGroup.error = action.payload && getProperErrorMessagesHendelar(action.payload);
    });
    // update plan group features
    builder.addCase(updatePlanGroupFeatures.pending, (state: IPlanGroupInitialStateType) => {
      state.updatePlanGroupFeatures.loading = true;
      state.updatePlanGroupFeatures.success = false;
      state.updatePlanGroupFeatures.error = null;
    });
    builder.addCase(updatePlanGroupFeatures.fulfilled, (state: IPlanGroupInitialStateType) => {
      state.updatePlanGroupFeatures.loading = false;
      state.updatePlanGroupFeatures.success = true;
      state.updatePlanGroupFeatures.error = null;
    });
    builder.addCase(updatePlanGroupFeatures.rejected, (state: IPlanGroupInitialStateType, action: PayloadAction<any>) => {
      state.updatePlanGroupFeatures.loading = false;
      state.updatePlanGroupFeatures.success = true;
      state.updatePlanGroupFeatures.error = action.payload && getProperErrorMessagesHendelar(action.payload);
    });
    // delete plan group
    builder.addCase(deletePlanGroup.pending, (state: IPlanGroupInitialStateType) => {
      state.deletePlanGroup.loading = true;
      state.deletePlanGroup.success = false;
      state.deletePlanGroup.error = null;
    });
    builder.addCase(deletePlanGroup.fulfilled, (state: IPlanGroupInitialStateType) => {
      state.deletePlanGroup.loading = false;
      state.deletePlanGroup.success = true;
      state.deletePlanGroup.error = null;
    });
    builder.addCase(deletePlanGroup.rejected, (state: IPlanGroupInitialStateType, action: PayloadAction<any>) => {
      state.deletePlanGroup.loading = false;
      state.deletePlanGroup.success = true;
      state.deletePlanGroup.error = action.payload && getProperErrorMessagesHendelar(action.payload);
    });
  },
});

export const { setSelectedPlanGroupByIdFromLocalState, updateGroupFeatureValueBySlug, attachPlanGroupFeature } = planGroupSlice.actions;
export default planGroupSlice.reducer;
