import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { IPagedData } from "models/Common.d";
import { IDiscountDataType, IDiscountInputFilter, IDiscountResponseFilter } from "models/Discount";
import { ICommonResponseType, ICommonStateType, linksInitialValue, metaInitialValue } from "redux/reducers/common";

import DiscountServices from "services/DiscountServices";
import { getProperErrorMessagesHendelar } from "utils/errorHandelar";

interface IDiscountInitialStateType {
  addDiscount: ICommonStateType;
  editDiscount: ICommonStateType;
  deleteDiscount: {
    success: boolean;
    loading: boolean;
    error: null | object;
  };
  getDiscounts: {
    result: IPagedData<IDiscountDataType, IDiscountResponseFilter>;
    success: boolean;
    loading: boolean;
    error: any;
    unAuthorized: boolean;
    page: number | null;
    per_page: number | null;
  };
  singleDiscount: {
    result: IDiscountDataType | null;
    success: boolean;
    loading: boolean;
    error: any;
  };
}

type updateArgumentsDataType = {
  data: {
    name: string;
    coupon_code: string;
    is_active: boolean;
  };
  id: string | number;
  filters: IDiscountInputFilter;
};

const initialState: IDiscountInitialStateType = {
  addDiscount: {
    success: false,
    loading: false,
    error: {
      validationErrors: [],
      othersErrors: {},
    },
  },
  editDiscount: {
    success: false,
    loading: false,
    error: {
      validationErrors: [],
      othersErrors: {},
    },
  },
  deleteDiscount: {
    success: false,
    loading: false,
    error: null,
  },
  getDiscounts: {
    result: {
      data: [],
      links: linksInitialValue,
      meta: metaInitialValue,
      filters: {},
      success: false,
      message: "",
    },
    success: false,
    loading: false,
    error: null,
    unAuthorized: false,
    page: null,
    per_page: null,
  },
  singleDiscount: {
    result: null,
    success: false,
    loading: false,
    error: null,
  },
};

// get all discount list

export const getDiscounts = createAsyncThunk<IPagedData<IDiscountDataType, IDiscountResponseFilter>, { filters?: IDiscountInputFilter }>(
  "discount/discountList",
  async (_, thunkApi) => {
    try {
      const data: IPagedData<IDiscountDataType, IDiscountResponseFilter> = await DiscountServices.getDiscount(_.filters);
      return data;
    } catch (error) {
      const modifyError: any = error;

      return thunkApi.rejectWithValue(modifyError);
    }
  },
);

// get discount by Id

export const singleDiscount = createAsyncThunk<IPagedData<IDiscountDataType, IDiscountResponseFilter>, { id: string | number }>(
  "discount/singleDiscount",
  async (_, thunkApi) => {
    try {
      const data = await DiscountServices.getDiscountById(_.id);
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

// add discount
export const addDiscount = createAsyncThunk<ICommonResponseType, IDiscountDataType>("discount/adddiscount", async (value, thunkApi) => {
  try {
    const data: ICommonResponseType = await DiscountServices.addDiscount(value);

    if (data) {
      message.success("Successfully  added");
      // window.location.search = "";
    }
    thunkApi.dispatch(getDiscounts({}));

    return data;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

// update discount async thunk

export const updateDiscount = createAsyncThunk<ICommonResponseType, updateArgumentsDataType>("discount/updateById", async (_, thunkApi) => {
  try {
    const data: ICommonResponseType = await DiscountServices.updateDiscountById(_.id, _.data);

    if (data.success) {
      message.success(data.message);
    }
    thunkApi.dispatch(getDiscounts({ filters: _.filters }));

    return data;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

// delete discount

export const deleteDiscount = createAsyncThunk<ICommonResponseType, { id: string | number; filters?: IDiscountInputFilter }>(
  "discount/delete",
  async (_, thunkApi) => {
    try {
      const data: ICommonResponseType = await DiscountServices.deleteDiscount(_.id);

      if (data.success) {
        message.success("Discount delete successfully");
      }
      thunkApi.dispatch(getDiscounts({ filters: _.filters }));

      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

// reducers -> reduce to a specific state -> changes state
export const discountSlice = createSlice({
  name: "discount",
  initialState,
  reducers: {
    setPageNumber: (state: IDiscountInitialStateType, action: PayloadAction<number>) => {
      state.getDiscounts.page = action.payload;
    },
    setPerPage: (state: IDiscountInitialStateType, action: PayloadAction<number>) => {
      state.getDiscounts.per_page = action.payload;
    },
    selectedDiscountByIdFromLocalState: (state: IDiscountInitialStateType, action: PayloadAction<number | string>) => {
      if (state.getDiscounts.result.data.length > 0) {
        const selectedDiscount = state.getDiscounts.result.data.find((x) => x.id === action.payload);

        if (selectedDiscount) state.singleDiscount.result = selectedDiscount;
      }
    },

    resetDiscountState: (state: IDiscountInitialStateType, action: PayloadAction) => {
      state.addDiscount.success = false;
      state.editDiscount.success = false;
    },
  },
  extraReducers: (builder) => {
    // get discount data
    builder.addCase(getDiscounts.pending, (state: IDiscountInitialStateType) => {
      state.getDiscounts.loading = true;
      state.getDiscounts.success = false;
      state.getDiscounts.error = false;
      state.getDiscounts.unAuthorized = false;
    });
    builder.addCase(
      getDiscounts.fulfilled,
      (state: IDiscountInitialStateType, action: PayloadAction<IPagedData<IDiscountDataType, IDiscountResponseFilter>>) => {
        state.getDiscounts.success = true;
        state.getDiscounts.result = action.payload;
        state.getDiscounts.loading = false;
        state.getDiscounts.error = false;
      },
    );
    builder.addCase(getDiscounts.rejected, (state: IDiscountInitialStateType, action: PayloadAction<any>) => {
      state.getDiscounts.success = false;
      state.getDiscounts.loading = false;
      state.getDiscounts.result.data = [];
      state.getDiscounts.error = action.payload.error;
      if (action.payload.error && action.payload.error.response.status === 403) {
        state.getDiscounts.unAuthorized = true;
      }
    });

    // get single discount data
    builder.addCase(singleDiscount.pending, (state: IDiscountInitialStateType) => {
      state.singleDiscount.loading = true;
      state.singleDiscount.success = false;
      state.singleDiscount.error = false;
    });
    builder.addCase(
      singleDiscount.fulfilled,
      (state: IDiscountInitialStateType, action: PayloadAction<IPagedData<IDiscountDataType, IDiscountResponseFilter>>) => {
        state.singleDiscount.success = true;
        state.singleDiscount.result = action.payload.data[0];
        state.singleDiscount.loading = false;
        state.singleDiscount.error = false;
      },
    );
    builder.addCase(singleDiscount.rejected, (state: IDiscountInitialStateType, action: PayloadAction<any>) => {
      state.singleDiscount.success = false;
      state.singleDiscount.loading = false;
      state.singleDiscount.error = action.payload;
    });

    // add discount
    builder.addCase(addDiscount.pending, (state: IDiscountInitialStateType) => {
      state.addDiscount.loading = true;
      state.addDiscount.success = false;
      state.addDiscount.error = null;
    });
    builder.addCase(addDiscount.fulfilled, (state: IDiscountInitialStateType) => {
      state.addDiscount.success = true;
      state.addDiscount.loading = false;
      state.addDiscount.error = null;
    });
    builder.addCase(addDiscount.rejected, (state: IDiscountInitialStateType, action: PayloadAction<any>) => {
      state.addDiscount.success = false;
      state.addDiscount.loading = false;
      state.addDiscount.error = action.payload && getProperErrorMessagesHendelar(action.payload);
    });

    // update discount
    builder.addCase(updateDiscount.pending, (state: IDiscountInitialStateType) => {
      state.editDiscount.loading = true;
      state.editDiscount.success = false;
      state.editDiscount.error = null;
    });
    builder.addCase(updateDiscount.fulfilled, (state: IDiscountInitialStateType) => {
      state.editDiscount.success = true;
      state.editDiscount.loading = false;
      state.editDiscount.error = null;
    });
    builder.addCase(updateDiscount.rejected, (state: IDiscountInitialStateType, action: PayloadAction<any>) => {
      state.editDiscount.success = false;
      state.editDiscount.loading = false;
      state.editDiscount.error = action.payload && getProperErrorMessagesHendelar(action.payload);
    });

    // delete discount
    builder.addCase(deleteDiscount.pending, (state: IDiscountInitialStateType) => {
      state.deleteDiscount.loading = true;
      state.deleteDiscount.success = false;
      state.deleteDiscount.error = null;
    });
    builder.addCase(deleteDiscount.fulfilled, (state: IDiscountInitialStateType) => {
      state.deleteDiscount.success = true;
      state.deleteDiscount.loading = false;
      state.deleteDiscount.error = null;
    });
    builder.addCase(deleteDiscount.rejected, (state: IDiscountInitialStateType, action: PayloadAction<any>) => {
      state.deleteDiscount.success = false;
      state.deleteDiscount.loading = false;
      state.deleteDiscount.error = action.payload;
    });
  },
});

export const { setPageNumber, selectedDiscountByIdFromLocalState, resetDiscountState, setPerPage } = discountSlice.actions;
export default discountSlice.reducer;
