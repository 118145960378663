import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { LOCAL_STORAGE_AUTHORITY_KEY } from "consts/storage";
import {
  IGroupedPermissionDataType,
  IPermissionDataType,
  IPermissionFilter,
  IPermissionQuery,
  IPermissionUpdateType,
  IRoleQuery
} from "models/Acl";
import { IPagedData } from "models/Common.d";
import { ICommonStateType, linksInitialValue, metaInitialValue } from "redux/reducers/common";
import AclService from "services/AclService";
import { setAuthority } from "utils/authority";
import { getProperErrorMessagesHendelar } from "utils/errorHandelar";

interface IPermissionInitialStateType {
  getPermissions: {
    result: IPagedData<IPermissionDataType, IPermissionFilter>;
    success: boolean;
    loading: boolean;
    error: any;
    unAuthorized: boolean;
    page: number | null;
    per_page: number | null;
  };
  getPermissionsForCurrentUser: {
    result: IPermissionDataType[];
    success: boolean;
    loading: boolean;
    error: any;
    unAuthorized: boolean;
  };
  getGroupedPermissions: {
    result: IGroupedPermissionDataType[];
    success: boolean;
    loading: boolean;
    error: any;
    unAuthorized: boolean;
  };
  updatePermission: ICommonStateType;
}

interface ICommonResponseType {
  message: string | null;
  success: boolean;
}

type updateArgumentsDataType = {
  data: IPermissionUpdateType;
  id: number;
  filters: IRoleQuery;
};

const initialState: IPermissionInitialStateType = {
  getPermissions: {
    result: {
      data: [],
      links: linksInitialValue,
      meta: metaInitialValue,
      filters: {},
      success: false,
      message: "",
    },
    success: false,
    loading: false,
    error: null,
    unAuthorized: false,
    page: null,
    per_page: null,
  },
  getPermissionsForCurrentUser: {
    result: [],
    success: false,
    loading: false,
    error: null,
    unAuthorized: false,
  },
  getGroupedPermissions: {
    result: [],
    success: false,
    loading: false,
    error: null,
    unAuthorized: false,
  },
  updatePermission: {
    success: false,
    loading: false,
    error: {
      validationErrors: [],
      othersErrors: {},
    },
  },
};

// get all permissions
export const getPermissions = createAsyncThunk<IPagedData<IPermissionDataType, IPermissionFilter>, { filters: IPermissionQuery }>(
  "permission/get-permissions",
  async (_, thunkApi) => {
    try {
      const data: IPagedData<IPermissionDataType, IPermissionFilter> = await AclService.getPermissions(_.filters);
      return data;
    } catch (error) {
      const modifyError: any = error;

      return thunkApi.rejectWithValue(modifyError);
    }
  },
);

export const getPermissionsForCurrentUser = createAsyncThunk<IPermissionDataType[], { token?: string }>(
  "permission/get-permissions-for-current-user",
  async (_, thunkApi) => {
    try {
      const data: { data: IPermissionDataType[]; success: boolean } = await AclService.getPermissionsForCurrentUser(_.token);
      if (data.success) {
        setAuthority(data.data.map((permission) => permission.name));
        localStorage.setItem(LOCAL_STORAGE_AUTHORITY_KEY, JSON.stringify(data.data.map((permission) => permission.name)));
      }
      return data.data;
    } catch (error) {
      const modifyError: any = error;

      return thunkApi.rejectWithValue(modifyError);
    }
  },
);

// get grouped permissions
export const getGroupedPermissions = createAsyncThunk<IGroupedPermissionDataType[]>(
  "permission/get-grouped-permissions",
  async (_, thunkApi) => {
    try {
      const data: { data: IGroupedPermissionDataType[]; success: boolean } = await AclService.getGroupedPermissions();
      return data.data;
    } catch (error) {
      const modifyError: any = error;
      return thunkApi.rejectWithValue(modifyError);
    }
  },
);

// update permission
export const updatePermission = createAsyncThunk<ICommonResponseType, updateArgumentsDataType>(
  "role/updatePermission",
  async (_, thunkApi) => {
    try {
      const data: ICommonResponseType = await AclService.updatePermission(_.id, _.data);

      if (data.success) {
        message.success(data.message);
      }
      thunkApi.dispatch(getPermissions({ filters: _.filters }));

      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

// reducers -> reduce to a specific state -> changes state
export const permissionSlice = createSlice({
  name: "permission",
  initialState,
  reducers: {
    setPageNumber: (state: IPermissionInitialStateType, action: PayloadAction<number>) => {
      state.getPermissions.page = action.payload;
    },
    setPerPage: (state: IPermissionInitialStateType, action: PayloadAction<number>) => {
      state.getPermissions.per_page = action.payload;
    },
    resetPermissionState: (state: IPermissionInitialStateType, action: PayloadAction) => {
      state.getPermissions.success = false;
      state.getPermissionsForCurrentUser.success = false;
    },
  },
  extraReducers: (builder) => {
    // get permissions
    builder.addCase(getPermissions.pending, (state: IPermissionInitialStateType) => {
      state.getPermissions.loading = true;
      state.getPermissions.success = false;
      state.getPermissions.error = false;
      state.getPermissions.unAuthorized = false;
    });
    builder.addCase(
      getPermissions.fulfilled,
      (state: IPermissionInitialStateType, action: PayloadAction<IPagedData<IPermissionDataType, IPermissionFilter>>) => {
        state.getPermissions.success = true;
        state.getPermissions.result = action.payload;
        state.getPermissions.loading = false;
        state.getPermissions.error = false;
      },
    );
    builder.addCase(getPermissions.rejected, (state: IPermissionInitialStateType, action: PayloadAction<any>) => {
      state.getPermissions.success = false;
      state.getPermissions.loading = false;
      state.getPermissions.result.data = [];
      state.getPermissions.error = action.payload.error;
      if (action.payload.error && action.payload.error.response.status === 403) {
        state.getPermissions.unAuthorized = true;
      }
    });
    // get permissions for user
    builder.addCase(getPermissionsForCurrentUser.pending, (state: IPermissionInitialStateType) => {
      state.getPermissionsForCurrentUser.loading = true;
      state.getPermissionsForCurrentUser.success = false;
      state.getPermissionsForCurrentUser.error = false;
      state.getPermissionsForCurrentUser.unAuthorized = false;
    });
    builder.addCase(
      getPermissionsForCurrentUser.fulfilled,
      (state: IPermissionInitialStateType, action: PayloadAction<IPermissionDataType[]>) => {
        state.getPermissionsForCurrentUser.success = true;
        state.getPermissionsForCurrentUser.result = action.payload;
        state.getPermissionsForCurrentUser.loading = false;
        state.getPermissionsForCurrentUser.error = false;
      },
    );
    builder.addCase(getPermissionsForCurrentUser.rejected, (state: IPermissionInitialStateType, action: PayloadAction<any>) => {
      state.getPermissionsForCurrentUser.success = false;
      state.getPermissionsForCurrentUser.loading = false;
      state.getPermissionsForCurrentUser.result = [];
      state.getPermissionsForCurrentUser.error = action.payload.error;
      if (action.payload.error && action.payload.error.response.status === 403) {
        state.getPermissionsForCurrentUser.unAuthorized = true;
      }
    });

    // get grouped permissions
    builder.addCase(getGroupedPermissions.pending, (state: IPermissionInitialStateType) => {
      state.getGroupedPermissions.loading = true;
      state.getGroupedPermissions.success = false;
      state.getGroupedPermissions.error = false;
      state.getGroupedPermissions.unAuthorized = false;
    });

    builder.addCase(
      getGroupedPermissions.fulfilled,
      (state: IPermissionInitialStateType, action: PayloadAction<IGroupedPermissionDataType[]>) => {
        state.getGroupedPermissions.success = true;
        state.getGroupedPermissions.result = action.payload;
        state.getGroupedPermissions.loading = false;
        state.getGroupedPermissions.error = false;
      },
    );
    builder.addCase(getGroupedPermissions.rejected, (state: IPermissionInitialStateType, action: PayloadAction<any>) => {
      state.getGroupedPermissions.success = false;
      state.getGroupedPermissions.loading = false;
      state.getGroupedPermissions.result = [];
      state.getGroupedPermissions.error = action.payload.error;
      if (action.payload.error && action.payload.error.response.status === 403) {
        state.getGroupedPermissions.unAuthorized = true;
      }
    });

    // update permission
    builder.addCase(updatePermission.pending, (state: IPermissionInitialStateType) => {
      state.updatePermission.loading = true;
      state.updatePermission.success = false;
      state.updatePermission.error = null;
    });
    builder.addCase(updatePermission.fulfilled, (state: IPermissionInitialStateType) => {
      state.updatePermission.success = true;
      state.updatePermission.loading = false;
      state.updatePermission.error = null;
    });
    builder.addCase(updatePermission.rejected, (state: IPermissionInitialStateType, action: PayloadAction<any>) => {
      state.updatePermission.success = false;
      state.updatePermission.loading = false;
      state.updatePermission.error = action.payload && getProperErrorMessagesHendelar(action.payload);
    });
  },
});

export const { setPageNumber, resetPermissionState, setPerPage } = permissionSlice.actions;
export default permissionSlice.reducer;
