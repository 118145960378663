import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import ErrorPage from "views/components/common/Error";
import RouterView from "./views/components/RouterView/RouterView";

import "./App.css";

Sentry.init({
  dsn: "https://56c62c25b8ad428a9ed612837497a92b@o1074927.ingest.sentry.io/6076409",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const App = () => {
  return (
    <Sentry.ErrorBoundary fallback={(error) => <ErrorPage error={error} />}>
      <RouterView />
    </Sentry.ErrorBoundary>
  );
};

export default App;
