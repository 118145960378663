import { MenuDataItem } from "@ant-design/pro-layout";
import { IPlanFeatureModelNameType, IPlanFeatureModelType } from "models/Plan";

/**
 * Whether the current pathname exists in the route, if so, returns the current object, if not, it is undefined
 * props.route.routes
 * @param router [{}]
 * @param pathname string
 */
export const getAuthorityFromRouter = <T extends MenuDataItem>(
    router: T[] = [],
    pathname: string,
): T | undefined => {
    const authority = router.find(
        ({children, path = '/'}) =>
            (path && path === pathname) ||
            (children && getAuthorityFromRouter(children, pathname)),
    );
    if (authority) return authority;
    return undefined;
};


// Cookie helper
export const cookie = {
    /**
     * @param name cookie
     * @param value cookie
     */
    setCookie: function (name: string, value: string) {
        const curDate = new Date();
        const curTamp = curDate.getTime();
        const curDay = curDate.toLocaleDateString();
        let curWeeHours = 0;
        if (
            /Safari/.test(navigator.userAgent) &&
            !/Chrome/.test(navigator.userAgent)
        ) {
            curWeeHours = new Date(curDay).getTime() + 8 * 60 * 60 * 1000 - 1;
        } else {
            curWeeHours = new Date(curDay).getTime() - 1;
        }
        const passedTamp = curTamp - curWeeHours;
        let leftTamp = 24 * 60 * 60 * 1000 - passedTamp;
        if (
            !(
                /Safari/.test(navigator.userAgent) &&
                !/Chrome/.test(navigator.userAgent)
            )
        ) {
            leftTamp = leftTamp + 8 * 60 * 60 * 1000;
        }
        const leftTime = new Date();
        leftTime.setTime(leftTamp + curTamp);
        document.cookie =
            name + '=' + value + ';expires=' + leftTime.toUTCString() + ';path=/';
    },
    getCookie: function (name: string) {
        const reg = /\s/g;
        const result = document.cookie.replace(reg, '');
        const resultArr = result.split(';');
        for (let i = 0; i < resultArr.length; i++) {
            const nameArr = resultArr[i].split('=');
            if (nameArr[0] === name) {
                return nameArr[1];
            }
        }
    },
    removeCookie: function (name: string) {
        const oDate = new Date();
        oDate.setDate(new Date().getDate() - 1);
        document.cookie = name + '=123;expires=' + oDate + ';path=/';
    },
};




export const getCurrencySymbol = (code: string): (string | undefined) => {
    const currency_symbols = {
        'USD': '$', // US Dollar
        'EUR': '€', // Euro
        'CRC': '₡', // Costa Rican Colón
        'GBP': '£', // British Pound Sterling
        'ILS': '₪', // Israeli New Sheqel
        'INR': '₹', // Indian Rupee
        'JPY': '¥', // Japanese Yen
        'KRW': '₩', // South Korean Won
        'NGN': '₦', // Nigerian Naira
        'PHP': '₱', // Philippine Peso
        'PLN': 'zł', // Polish Zloty
        'PYG': '₲', // Paraguayan Guarani
        'THB': '฿', // Thai Baht
        'UAH': '₴', // Ukrainian Hryvnia
        'VND': '₫', // Vietnamese Dong
    };
    // @ts-ignore
    return currency_symbols[code]
}

export const getColorByAllowance = (name: IPlanFeatureModelNameType) => {
    switch (name) {
      case "orderFulfilmentAllowance":
        return "#faad14";
      case "videoAllowance":
        return "rgb(104 167 203)";
      case "storeAllowance":
        return "rgb(30 132 47)";
      case "shopAllowance":
        return "#87d068";

      case "importAllowance":
        return "#2db7f5";
      case "reviewAllowance":
        return "#108ee9";

      default:
        return "rgb(138 16 233)";
    }
  };
export const getFormattedNameByAllowance = (name: IPlanFeatureModelNameType | IPlanFeatureModelType) => {
    switch (name) {
      case "orderFulfilmentAllowance":
        return "Order fulfillment allowance";
      case "videoAllowance":
        return "Video allowance";
      case "storeAllowance":
        return "Store allowance";
      case "shopAllowance":
        return "Shop allowance";

      case "importAllowance":
        return "Import allowance";
      case "reviewAllowance":
        return "Review allowance";
      case "role":
        return "Role";
      case "allowance":
        return "Allowance";

      default:
        return name;
    }
  };