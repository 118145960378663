import { ILoginDataType, ISignUpDataType } from "../models/Auth";
import api_request from "./ApiConfig";

class AuthServices {
  login(data: ILoginDataType): Promise<any> {
    return api_request.post("/api/v1/auth/login", data);
  }

  signup(data: ISignUpDataType): Promise<any> {
    return api_request.post("/api/v1/auth/signup", data);
  }
}

export default new AuthServices();
