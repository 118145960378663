import { IStoreCreateType, IStoreQuery, IStoreUpdateType } from "models/Store";
import api_request from "./ApiConfig";

class StoreService {
  static getStores(params: IStoreQuery): Promise<any> {
    return api_request.get(`/api/v1/store`, params);
  }

  static addStore(data: IStoreCreateType): Promise<any> {
    return api_request.post(`/api/v1/store`, data);
  }

  static updateStore(id: number, data: IStoreUpdateType): Promise<any> {
    return api_request.patch(`/api/v1/store/${id}`, data);
  }
}

export default StoreService;
