import { Modal } from "antd";

interface IErrorHandelar {
  response: {
    status: number;
    data: {
      errors: any;
    };
  };
}

interface IErrorReturnType {
  validationErrors:
    | {
        label: string;
        message: string;
      }[]
    | [];
  othersErrors: {};
}

type processedErrorMessageType = {
  label: string;
  message: string;
};

const errorModal = () => {
  Modal.error({
    title: "Error Occurred",
    content: "Something went wrong! Try again later",
    okText: "ok",
  });
};

export function getProperErrorMessagesHendelar(error: IErrorHandelar): IErrorReturnType {
  if (error.response && error.response.status === 422) {
    let objectKeys: string[] = error?.response?.data?.errors && Object.keys(error?.response?.data?.errors);

    if (objectKeys && objectKeys.length > 0) {
      let errors: processedErrorMessageType[] = objectKeys.map((x, index) => {
        return {
          label: objectKeys[index],
          message: error.response.data.errors[x][0],
        };
      });
      return {
        validationErrors: errors,
        othersErrors: {},
      };
    }

    return {
      validationErrors: [],
      othersErrors: error,
    };
  } else {
    errorModal();
    return {
      validationErrors: [],
      othersErrors: error,
    };
  }
}
