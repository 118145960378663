import { IUserCreateDataType, IUserQuery, IUserRoleAssignType, IUserUpdateDataType } from "models/User";
import api_request from "./ApiConfig";

class UserService {
  getUsers(params?: IUserQuery): Promise<any> {
    let url: string = `/api/v1/user`;
    return api_request.get(url, params);
  }
  createUser(data: IUserCreateDataType): Promise<any> {
    return api_request.post(`/api/v1/user`, data);
  }
  updateUser(user_id: number, data: IUserUpdateDataType): Promise<any> {
    return api_request.patch(`/api/v1/user/${user_id}`, data);
  }

  assignUserRole(data: IUserRoleAssignType): Promise<any> {
    return api_request.post(`/api/v1/acl/role/sync-roles-user`, data);
  }
  impersonateLogIn(requestUserId: number): Promise<any> {
    return api_request.post(`/api/v1/impersonate/${requestUserId}`, {});
  }
}

export default new UserService();
