import { linksInitialValue, metaInitialValue } from "redux/reducers/common";

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { IPagedData, IQuery } from "models/Common.d";
import { IPlanFeatureUpdateDataType, IPlanInputFilter, IPlanResponseFilter } from "models/Plan.d";

import { getProperErrorMessagesHendelar } from "utils/errorHandelar";

import { IPlanApiResponseDataType, IPlanFeatureCreateDataType, IPlanFeatureType, IPlanType } from "models/Plan";
import PlanService from "services/PlanService";

interface IPlanCommonInitialStateType {
  success: boolean;
  loading: boolean;
  error: {
    validationErrors:
      | {
          label: string;
          message: string;
        }[]
      | [];
    othersErrors: {};
  } | null;
}

interface IPlanInitialStateType {
  addPlan: IPlanCommonInitialStateType;
  createPlanFeature: IPlanCommonInitialStateType;
  activePlan: IPlanCommonInitialStateType;
  deActivePlan: IPlanCommonInitialStateType;
  updatePlanFeature: IPlanCommonInitialStateType;
  deletePlanFeature: IPlanCommonInitialStateType;

  getPlans: {
    result: IPagedData<IPlanApiResponseDataType, IPlanResponseFilter>;
    success: boolean;
    loading: boolean;
    error: any;
    unAuthorized: boolean;
    page: number | null;
    per_page: number | null;
  };

  planFeaturesByPlan: {
    result: IPagedData<IPlanFeatureType, IPlanInputFilter>;
    success: boolean;
    loading: boolean;
    error: any;
  };
  syncPlanFeatures: IPlanCommonInitialStateType,
  singlePlan: {
    result: IPlanApiResponseDataType | null;
  };
}

type addPlanApiReturnType = {
  message: string | null;
  success: boolean;
  data: IPlanApiResponseDataType;
};
type apiReturnCommonType = {
  message: string | null;
  success: boolean;
};

const initialState: IPlanInitialStateType = {
  addPlan: {
    success: false,
    loading: false,
    error: {
      validationErrors: [],
      othersErrors: {},
    },
  },
  createPlanFeature: {
    success: false,
    loading: false,
    error: {
      validationErrors: [],
      othersErrors: {},
    },
  },

  activePlan: {
    loading: false,
    success: false,
    error: {
      validationErrors: [],
      othersErrors: {},
    },
  },
  deActivePlan: {
    loading: false,
    success: false,
    error: {
      validationErrors: [],
      othersErrors: {},
    },
  },
  updatePlanFeature: {
    loading: false,
    success: false,
    error: {
      validationErrors: [],
      othersErrors: {},
    },
  },
  deletePlanFeature: {
    loading: false,
    success: false,
    error: {
      validationErrors: [],
      othersErrors: {},
    },
  },
  getPlans: {
    result: {
      data: [],
      links: linksInitialValue,
      meta: metaInitialValue,
      filters: {},
      success: false,
      message: "",
    },
    success: false,
    loading: false,
    error: null,
    unAuthorized: false,
    page: null,
    per_page: null,
  },

  planFeaturesByPlan: {
    result: {
      data: [],
      links: linksInitialValue,
      meta: metaInitialValue,
      filters: {},
      success: false,
      message: "",
    },
    success: false,
    loading: false,
    error: null,
  },
  syncPlanFeatures: {
    loading: false,
    success: false,
    error: {
      validationErrors: [],
      othersErrors: {},
    },
  },
  singlePlan: {
    result: null,
  },

  // gatewayId: "",
};

// get all plans list

export const getPlans = createAsyncThunk<
  IPagedData<IPlanApiResponseDataType, IPlanResponseFilter>,
  { gateway_id?: string | number | null; filters?: IPlanInputFilter }
>("plan/planList", async (_, thunkApi) => {
  try {
    const data: IPagedData<IPlanApiResponseDataType, IPlanResponseFilter> = await PlanService.getPlans(_.gateway_id, _.filters);
    return data;
  } catch (error) {
    const modifyError: any = error;

    return thunkApi.rejectWithValue(modifyError);
  }
});
// get plan feature by plan id

export const getPlanFeatureByPlanId = createAsyncThunk<IPagedData<IPlanFeatureType, any>, { plan_id: string | number; filters?: IQuery }>(
  "plan/plan_feature_by_plan_id",
  async (_, thunkApi) => {
    try {
      const data: IPagedData<IPlanFeatureType, any> = await PlanService.getPlanFeaturesByPlanId(_.plan_id, _.filters);
      thunkApi.dispatch(resetPlanState());
      return data;
    } catch (error) {
      const modifyError: any = error;

      return thunkApi.rejectWithValue(modifyError);
    }
  },
);

export const syncPlanFeatures = createAsyncThunk<apiReturnCommonType, { payload: any }>(
  "plan/sync_plan_features",
  async (_, thunkApi) => {
    try {
      const data: apiReturnCommonType = await PlanService.syncPlanFeatures(_.payload);
      if (data.success) {
        message.success(data.message);
      }

      return data;
    } catch (error) {
      const modifyError: any = error;

      return thunkApi.rejectWithValue(modifyError);
    }
  },
);

// add  plan
export const addPlan = createAsyncThunk<addPlanApiReturnType, { data: IPlanType }>("plan/addPlan", async (_, thunkApi) => {
  try {
    const data: addPlanApiReturnType = await PlanService.addPlan(_.data);

    if (data.success) {
      message.success(data.message);
    }
    // window.location.search = "";
    thunkApi.dispatch(getPlans({ filters: { page: 1 } }));
    // window.location.search = "";

    return data;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});
// create plan feature
export const createPlanFeature = createAsyncThunk<apiReturnCommonType, { data: IPlanFeatureCreateDataType }>(
  "plan/create_plan_feature",
  async (_, thunkApi) => {
    try {
      const data: apiReturnCommonType = await PlanService.createPlanFeature(_.data);

      if (data.success) {
        message.success(data.message);
      }
      // window.location.search = "";

      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

// update plan feature

export const updatePlanFeature = createAsyncThunk<
  apiReturnCommonType,
  { filters?: IQuery; planFeatureId: string | number; data: IPlanFeatureUpdateDataType }
>("plan/update_plan_feature", async (_, thunkApi) => {
  try {
    const data: apiReturnCommonType = await PlanService.updatePlanFeature(_.data, _.planFeatureId);

    if (data.success) {
      message.success(data.message);
    }
    thunkApi.dispatch(getPlanFeatureByPlanId({ plan_id: _.data.plan_id, filters: _.filters }));

    return data;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});
// delete plan feature

export const deletePlanFeature = createAsyncThunk<
  apiReturnCommonType,
  { filters?: IQuery; planFeatureId: string | number; planId: string | number }
>("plan/delete_plan_feature", async (_, thunkApi) => {
  try {
    const data: apiReturnCommonType = await PlanService.deletePlanFeature(_.planFeatureId);

    if (data.success) {
      message.success(data.message);
    }

    thunkApi.dispatch(getPlanFeatureByPlanId({ plan_id: _.planId, filters: _.filters }));

    return data;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

//active plan

export const activePlan = createAsyncThunk<apiReturnCommonType, { filters: IPlanInputFilter; target_plan_id: string | number }>(
  "plan/active",
  async (_, thunkApi) => {
    try {
      const data: apiReturnCommonType = await PlanService.activePlan(_.target_plan_id);

      if (data.success) {
        message.success(data.message);
      }
      thunkApi.dispatch(getPlans({ filters: _.filters }));

      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
//deactive  plan

export const deActivePlan = createAsyncThunk<apiReturnCommonType, { filters?: IPlanInputFilter; target_plan_id: string | number }>(
  "plan/deActive",
  async (_, thunkApi) => {
    try {
      const data: apiReturnCommonType = await PlanService.deActivePlan(_.target_plan_id);

      if (data.success) {
        message.success(data.message);
      }
      thunkApi.dispatch(getPlans({ filters: _.filters }));

      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

// reducers -> reduce to a specific state -> changes state
export const planSlice = createSlice({
  name: "plan",
  initialState,
  reducers: {
    setPageNumber: (state: IPlanInitialStateType, action: PayloadAction<number>) => {
      state.getPlans.page = action.payload;
    },
    setPerPage: (state: IPlanInitialStateType, action: PayloadAction<number>) => {
      state.getPlans.per_page = action.payload;
    },
    setSelectedPlanByIdFromLocalState: (state: IPlanInitialStateType, action: PayloadAction<number | string>) => {
      const selectedPlan = state.getPlans.result.data.find((x) => x.id === action.payload);

      if (selectedPlan) state.singlePlan.result = selectedPlan;
    },

    resetPlanState: (state: IPlanInitialStateType) => {
      state.addPlan.success = false;
      state.activePlan.success = false;
      state.deActivePlan.success = false;
      state.deletePlanFeature.success = false;
      state.updatePlanFeature.success = false;
      state.createPlanFeature.success = false;
    },
  },
  extraReducers: (builder) => {
    // get plan  data
    builder.addCase(getPlans.pending, (state: IPlanInitialStateType) => {
      state.getPlans.loading = true;
      state.getPlans.success = false;
      state.getPlans.error = false;
      state.getPlans.unAuthorized = false;
    });
    builder.addCase(
      getPlans.fulfilled,
      (state: IPlanInitialStateType, action: PayloadAction<IPagedData<IPlanApiResponseDataType, IPlanResponseFilter>>) => {
        state.getPlans.success = true;
        state.getPlans.result = action.payload;
        state.getPlans.loading = false;
        state.getPlans.error = false;
      },
    );
    builder.addCase(getPlans.rejected, (state: IPlanInitialStateType, action: PayloadAction<any>) => {
      state.getPlans.success = false;
      state.getPlans.result.data = [];
      state.getPlans.loading = false;
      state.getPlans.error = action.payload.error;
      if (action.payload.error && action.payload.error.response.status === 403) {
        state.getPlans.unAuthorized = true;
      }
    });

    // get plan  feature data by plan id
    builder.addCase(getPlanFeatureByPlanId.pending, (state: IPlanInitialStateType) => {
      state.planFeaturesByPlan.loading = true;
      state.planFeaturesByPlan.success = false;
      state.planFeaturesByPlan.error = false;
    });
    builder.addCase(
      getPlanFeatureByPlanId.fulfilled,
      (state: IPlanInitialStateType, action: PayloadAction<IPagedData<IPlanFeatureType, any>>) => {
        state.planFeaturesByPlan.success = true;
        state.planFeaturesByPlan.result = action.payload;
        state.planFeaturesByPlan.loading = false;
        state.planFeaturesByPlan.error = false;
      },
    );
    builder.addCase(getPlanFeatureByPlanId.rejected, (state: IPlanInitialStateType, action: PayloadAction<any>) => {
      state.planFeaturesByPlan.success = false;
      state.planFeaturesByPlan.result.data = [];
      state.planFeaturesByPlan.loading = false;
      state.planFeaturesByPlan.error = action.payload.error;
    });

    // add plan
    builder.addCase(addPlan.pending, (state: IPlanInitialStateType) => {
      state.addPlan.loading = true;
      state.addPlan.success = false;
      state.addPlan.error = null;
    });
    builder.addCase(addPlan.fulfilled, (state: IPlanInitialStateType) => {
      state.addPlan.success = true;
      state.addPlan.loading = false;
      state.addPlan.error = null;
    });
    builder.addCase(addPlan.rejected, (state: IPlanInitialStateType, action: PayloadAction<any>) => {
      state.addPlan.success = false;
      state.addPlan.loading = false;
      state.addPlan.error = action.payload && getProperErrorMessagesHendelar(action.payload);

      //  if (action.payload?.error?.response.status === 422) {
      //   state.getService.error =getProperErrorMessages(action.payload.error) ;
      // };
    });
    // create plan feature
    builder.addCase(createPlanFeature.pending, (state: IPlanInitialStateType) => {
      state.createPlanFeature.loading = true;
      state.createPlanFeature.success = false;
      state.createPlanFeature.error = null;
    });
    builder.addCase(createPlanFeature.fulfilled, (state: IPlanInitialStateType) => {
      state.createPlanFeature.success = true;
      state.createPlanFeature.loading = false;
      state.createPlanFeature.error = null;
    });
    builder.addCase(createPlanFeature.rejected, (state: IPlanInitialStateType, action: PayloadAction<any>) => {
      state.createPlanFeature.success = false;
      state.createPlanFeature.loading = false;
      state.createPlanFeature.error = action.payload && getProperErrorMessagesHendelar(action.payload);

      //  if (action.payload?.error?.response.status === 422) {
      //   state.getService.error =getProperErrorMessages(action.payload.error) ;
      // };
    });

    // active plan
    builder.addCase(activePlan.pending, (state: IPlanInitialStateType) => {
      state.activePlan.loading = true;
      state.activePlan.success = false;
      state.activePlan.error = null;
    });
    builder.addCase(activePlan.fulfilled, (state: IPlanInitialStateType) => {
      state.activePlan.loading = false;
      state.activePlan.success = true;
      state.activePlan.error = null;
    });
    builder.addCase(activePlan.rejected, (state: IPlanInitialStateType, action: PayloadAction<any>) => {
      state.activePlan.loading = false;
      state.activePlan.success = true;
      state.activePlan.error = action.payload && getProperErrorMessagesHendelar(action.payload);
    });

    // deActive plan
    builder.addCase(deActivePlan.pending, (state: IPlanInitialStateType) => {
      state.deActivePlan.loading = true;
      state.deActivePlan.success = false;
      state.deActivePlan.error = null;
    });
    builder.addCase(deActivePlan.fulfilled, (state: IPlanInitialStateType) => {
      state.deActivePlan.loading = false;
      state.deActivePlan.success = true;
      state.deActivePlan.error = null;
    });
    builder.addCase(deActivePlan.rejected, (state: IPlanInitialStateType, action: PayloadAction<any>) => {
      state.deActivePlan.loading = false;
      state.deActivePlan.success = true;
      state.deActivePlan.error = action.payload && getProperErrorMessagesHendelar(action.payload);
    });
    // update plan feature
    builder.addCase(updatePlanFeature.pending, (state: IPlanInitialStateType) => {
      state.updatePlanFeature.loading = true;
      state.updatePlanFeature.success = false;
      state.updatePlanFeature.error = null;
    });
    builder.addCase(updatePlanFeature.fulfilled, (state: IPlanInitialStateType) => {
      state.updatePlanFeature.loading = false;
      state.updatePlanFeature.success = true;
      state.updatePlanFeature.error = null;
    });
    builder.addCase(updatePlanFeature.rejected, (state: IPlanInitialStateType, action: PayloadAction<any>) => {
      state.updatePlanFeature.loading = false;
      state.updatePlanFeature.success = true;
      state.updatePlanFeature.error = action.payload && getProperErrorMessagesHendelar(action.payload);
    });
    // delete plan feature
    builder.addCase(deletePlanFeature.pending, (state: IPlanInitialStateType) => {
      state.deletePlanFeature.loading = true;
      state.deletePlanFeature.success = false;
      state.deletePlanFeature.error = null;
    });
    builder.addCase(deletePlanFeature.fulfilled, (state: IPlanInitialStateType) => {
      state.deletePlanFeature.loading = false;
      state.deletePlanFeature.success = true;
      state.deletePlanFeature.error = null;
    });
    builder.addCase(deletePlanFeature.rejected, (state: IPlanInitialStateType, action: PayloadAction<any>) => {
      state.deletePlanFeature.loading = false;
      state.deletePlanFeature.success = true;
      state.deletePlanFeature.error = action.payload && getProperErrorMessagesHendelar(action.payload);
    });

    builder.addCase(syncPlanFeatures.pending, (state: IPlanInitialStateType) => {
      state.syncPlanFeatures.loading = true;
      state.syncPlanFeatures.success = false;
      state.syncPlanFeatures.error = null;
    });

    builder.addCase(syncPlanFeatures.fulfilled, (state: IPlanInitialStateType, action: PayloadAction<any>) => {
      state.syncPlanFeatures.loading = false;
      state.syncPlanFeatures.success = true;
      state.syncPlanFeatures.error = null;
    });

    builder.addCase(syncPlanFeatures.rejected, (state: IPlanInitialStateType, action: PayloadAction<any>) => {
      state.syncPlanFeatures.loading = false;
      state.syncPlanFeatures.success = true;
      state.syncPlanFeatures.error = action.payload && getProperErrorMessagesHendelar(action.payload);
    });
  },
});

export const { setPageNumber, setSelectedPlanByIdFromLocalState, resetPlanState, setPerPage } = planSlice.actions;
export default planSlice.reducer;
