import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IPagedData } from "models/Common";
import { IGatewayDataType, IGatewayResponseFilter } from "models/PaymentGateway";
import { linksInitialValue, metaInitialValue } from "redux/reducers/common";
import PaymentServices from "services/PaymentServices";

interface listOfGatewayInitialStateType {
  result: IPagedData<IGatewayDataType, IGatewayResponseFilter>;
  loading: boolean;
  unAuthorized: boolean;
  errors: any;
  selectedGatewayId: number | string | null;

  singleGatewayData: {
    loading: boolean;
    result: IGatewayDataType | null;
    errors: any;
  };
}

const initialState: listOfGatewayInitialStateType = {
  result: {
    data: [],
    links: linksInitialValue,
    meta: metaInitialValue,
    filters: {},
    success: false,
    message: "",
  },
  loading: false,
  errors: null,
  unAuthorized: false,
  selectedGatewayId: null,
  singleGatewayData: {
    loading: false,
    result: null,
    errors: "",
  },
};

export const getListPaymentGateway = createAsyncThunk<IPagedData<IGatewayDataType, IGatewayResponseFilter>>(
  "paymentGateway/list",
  async (_, thunkApi) => {
    try {
      return await PaymentServices.getPaymentGateWay();
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const getSingleGatewayById = createAsyncThunk<IPagedData<IGatewayDataType, IGatewayResponseFilter>, { id: string | number }>(
  "paymentGateway/single",
  async (_, thunkApi) => {
    try {
      return await PaymentServices.getPaymentDetailsById(_.id);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

// reducers -> reduce to a specific state -> changes state
export const listOfGatewaySlice = createSlice({
  name: "paymentGateway",
  initialState,
  reducers: {
    setGatewayId: (state: listOfGatewayInitialStateType, action: PayloadAction<number | string>) => {
      state.selectedGatewayId = action.payload;
    },
    resetGatewayState: (state: listOfGatewayInitialStateType) => {
      state.selectedGatewayId = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getListPaymentGateway.pending, (state: listOfGatewayInitialStateType) => {
      state.loading = true;
      state.unAuthorized = false;
    });
    builder.addCase(
      getListPaymentGateway.fulfilled,
      (state: listOfGatewayInitialStateType, action: PayloadAction<IPagedData<IGatewayDataType, IGatewayResponseFilter>>) => {
        state.result = action.payload;
        state.loading = false;
        state.unAuthorized = false;
      },
    );
    builder.addCase(getListPaymentGateway.rejected, (state: listOfGatewayInitialStateType, action: PayloadAction<any>) => {
      state.loading = false;
      state.errors = action.payload.response;
      state.result.data = [];
      state.unAuthorized = action.payload.response && action.payload.response.status === 401 ? true : false;
    });

    builder.addCase(getSingleGatewayById.pending, (state) => {
      state.singleGatewayData.loading = true;
      state.singleGatewayData.errors = null;
    });
    builder.addCase(
      getSingleGatewayById.fulfilled,
      (state, action: PayloadAction<IPagedData<IGatewayDataType, IGatewayResponseFilter>>) => {
        state.singleGatewayData.result = action.payload.data[0];
        state.singleGatewayData.loading = false;
        state.singleGatewayData.errors = null;
      },
    );
    builder.addCase(getSingleGatewayById.rejected, (state, action: PayloadAction<any>) => {
      state.singleGatewayData.loading = false;
      state.singleGatewayData.errors = action.payload.response;
      state.singleGatewayData.result = null;
    });
  },
});

export const { setGatewayId, resetGatewayState } = listOfGatewaySlice.actions;

export default listOfGatewaySlice.reducer;
