import { IDiscountUserCreateDataType } from "models/DiscountUsers";
import api_request from "./ApiConfig";
import {allUserDiscount} from "../consts/appConstant";

class DiscountUserServices {
  getDiscountUsers(parems?: object): Promise<any> {
    let url: string = `/api/v1/discount-user`;
    return api_request.get(url, parems);
  }
  addDiscountUser(data: IDiscountUserCreateDataType): Promise<any> {
    if (data.user_id === allUserDiscount.value) {
      return api_request.post(`/api/v1/discount/make-universal`, data);
    }

    return api_request.post(`/api/v1/discount-user`, data)
  }
}

export default new DiscountUserServices();
