import { IGatewayDataType } from "../models/PaymentGateway";
import api_request from "./ApiConfig";

class PaymentService {
  getPaymentGateWay(): Promise<any> {
    return api_request.get("/api/v1/payment-gateway");
  }
  getPaymentDetailsById(id: string | number): Promise<any> {
    return api_request.get(`/api/v1/payment-gateway?id=${id}`);
  }
  updatePaymentGateWayId(id: string | number, values: IGatewayDataType): Promise<any> {
    return api_request.patch(`/api/v1/payment-gateway/${id}`, values);
  }
}

export default new PaymentService();
