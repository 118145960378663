import api_request from "./ApiConfig";

class DiscountUsageHistoryServices {
  getDiscountUsagesHistory(parems?: object): Promise<any> {
    let url: string = `/api/v1/discount-usage-history`;
    return api_request.get(url, parems);
  }
}

export default new DiscountUsageHistoryServices();
