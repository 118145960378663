import React from "react";

import styles from "./loader.module.css";

const BoxBorderLoader = () => {
  return (
    <div className={styles.BoxBorderLoader_loader_wrapper}>
      <div className={styles.BoxBorderLoader}>wait...</div>
    </div>
  );
};

export default BoxBorderLoader;
